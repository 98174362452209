<template>
  <div class="dashboard dashboard--customer-settings">
    <CustomerSettings />
  </div>
</template>

<script>
import CustomerSettings from '@/components/Settings/CustomerSettings'

export default {
  components: {
    CustomerSettings
  }
}
</script>
