const Browsers = {
  SAFARI: 'safari'
}

const Platforms = {
  IOS: 'ios',
  MACOS: 'macos',
  WINDOWS: 'windows'
}

export { Browsers, Platforms }
