import Bowser from 'bowser'

const getOffset = el => {
  const rect = el.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

const getUserAgent = () => {
  const userAgent = Bowser.parse(window.navigator.userAgent)
  return {
    os: userAgent.os.name.toLowerCase(),
    browser: userAgent.browser.name.toLowerCase()
  }
}

export { getOffset, getUserAgent }
