/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh': {
    width: 14,
    height: 17,
    viewBox: '0 0 14 17',
    data: '<g _fill="#42A5F5" fill-rule="evenodd"><path pid="0" d="M0 3.48v7.985c0 .218.185.395.412.395a.405.405 0 00.412-.395V3.48c0-.494.432-.91.947-.91h9.697l-1.05 1.009a.372.372 0 000 .553c.082.08.185.119.288.119.103 0 .206-.04.288-.119l1.75-1.68a.372.372 0 000-.553l-1.75-1.68a.41.41 0 00-.576 0 .372.372 0 000 .553l1.05 1.008H1.77C.803 1.78 0 2.55 0 3.48z" fill-opacity=".496"/><path pid="1" d="M13.588 5.14a.405.405 0 00-.412.395v7.986c0 .494-.432.91-.947.91H2.635l1.05-1.009a.372.372 0 000-.553.41.41 0 00-.576 0l-1.75 1.68a.372.372 0 000 .553l1.75 1.68c.082.08.185.12.288.12.103 0 .206-.04.288-.12a.372.372 0 000-.553l-1.05-1.008h9.594c.968 0 1.771-.771 1.771-1.7V5.535a.405.405 0 00-.412-.395z"/></g>'
  }
})
