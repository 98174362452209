class EmbedTwitter {
  constructor () {
    this.exists = !!document.querySelector('twitter')
  }

  async check () {
    while (!window.twttr || !window.twttr.widgets) {
      await new Promise((resolve, reject) => setTimeout(resolve, 50))
    }
  }

  async reload () {
    this.reset()

    if (this.exists) {
      await this.check()
      window.twttr.widgets.load()

      const tweets = Array.from([...document.querySelectorAll('twitter')])
      for (const tweet of tweets) {
        let id = tweet.dataset.src.split('/').pop()
        window.twttr.widgets.createTweet(
          id,
          tweet,
          {
            theme: 'light'
          }
        )
      }
    }
  }

  reset () {
    document.querySelectorAll('.twitter-tweet-rendered').forEach(e => e.remove())
  }
}

export default EmbedTwitter
