<template>
  <div class="instagram-media">
    <div v-html="dataPost" />
  </div>
</template>

<script>
import 'isomorphic-fetch'
import 'es6-promise'

export default {
  name: 'Instagram',
  props: ['node'],
  data () {
    return {
      dataPost: null
    }
  },
  computed: {
    src () {
      return this.node && this.node.attrs.src
    }
  },
  created () {
    let codeId = this.src
    if (this.src.includes('blockquote')) {
      const codeHref = new RegExp(
        /href="(https:\/\/([\s\S]*?)\/(p|reel)\/([\s\S]*?)\/([\s\S]*?))"/
      )
      codeId = codeHref.exec(this.src)[1]
    }

    const url = `https://api.instagram.com/oembed/?url=${codeId}`
    fetch(url)
      .then(res => {
        res
          .json()
          .then(data => {
            this.dataPost = data.html
          })
          .then(() => {
            // Embed instagram post
            window.instgrm.Embeds.process()
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }
}
</script>
