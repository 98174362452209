<template>
  <div id="viewer" class="liveblog-viewer" :style="currentCustomCssVariables">
    <DisplayAmp v-if="isAmp" />
    <Display
      v-else
      ref="display"
      :active-tab="activeTab"
      :toggle-tab="toggleTab"
      :load-more-posts="loadMorePosts"
      :is-loading-more-posts="isLoadingMorePosts"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import liveblogAPI from '@/api/LiveblogAPI'
import Display from '@/components/Display/Display'
import DisplayAmp from '@/components/Display/DisplayAmp'
import { DisplayTabs } from '@/constants/display'
import { PostsLimit } from '@/constants/pagination'
import { Browsers, Platforms } from '@/constants/userAgents'
import websockets from '@/constants/websockets'
import iframeConnector from '@/helpers/connectors/IframeConnector'
import { getUserAgent } from '@/helpers/utils/window'
import lazyLoad from '@/helpers/LazyLoad'

export default {
  components: { Display, DisplayAmp },
  props: {
    isAmp: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      liveId: this.$route.params.id,
      activeTab: DisplayTabs.LIVE,
      paginationOffset: 0,
      isLoadingMorePosts: false,
      listenersAreBinded: false,
      customVideoOptimizerURLsPrefixes: null
    }
  },
  computed: {
    ...mapGetters({
      posts: 'posts/posts',
      canLoadMorePosts: 'posts/canLoadMore',
      currentCustomCssVariables: 'lives/currentCustomCssVariables',
      currentCustomBackgroundColor: 'lives/currentCustomBackgroundColor',
      timelineEnabled: 'lives/currentTimelineEnabled'
    }),
    currentLive: {
      get () { return this.$store.getters['lives/currentLive'] },
      set (live) { this.$store.commit('lives/SET_CURRENT_LIVE', live) }
    },
    scoreboard: {
      get () { return this.$store.getters['scoreboard/scoreboard'] },
      set (value) { this.$store.commit('scoreboard/SET_SCOREBOARD', value) }
    },
    scoreboardActions: {
      get () { return this.$store.getters['scoreboard/scoreboardActions'] },
      set (value) { this.$store.commit('scoreboard/SET_SCOREBOARD_ACTIONS', value) }
    },
    disableLazyLoading () {
      return getUserAgent().os === Platforms.IOS || getUserAgent().browser === Browsers.SAFARI || this.isAmp
    }
  },
  async mounted () {
    try {
      const { live, customization } = await liveblogAPI.fetchLive(this.liveId)
      const liveData = { ...live, customization }
      this.customVideoOptimizerURLsPrefixes = customization.settings.videoOptimizerURLsPrefixes
      this.$store.dispatch('lives/setCurrentLive', { live: liveData, updateLocale: true })
    } catch (e) {
      return this.$noty.error(this.$t('app.error.default'))
    }

    await this.$store.dispatch('posts/fetchPosts', {
      liveId: this.liveId,
      formatImagesForLazyLoading: !this.disableLazyLoading,
      pagination: true,
      paginationOffset: 0
    })

    document.body.classList.add('display')
    if (this.currentCustomBackgroundColor) {
      document.body.setAttribute('style', `--color-background: ${this.currentCustomBackgroundColor}`)
    }

    /**
     * AMP case: we do not need to go further (no socket connection)
     */
    if (this.isAmp) { return }

    if (parent !== window) { iframeConnector.connect() } // hotfix: penpal connection can not be waited here.
    if (this.timelineEnabled && iframeConnector) { iframeConnector.startTimelineHandling() }
    this.reloadEmbeds()
    this.optimizeVideos()

    /**
     * Websockets
     */
    this.$socket().emit('join', this.liveId)
    this.$socket().on('joined', () => {
      if (this.listenersAreBinded) { this.unbindListeners() }
      this.bindListeners()
      this.listenersAreBinded = true
    })

    this.$socket().on('reconnect', () => {
      this.$socket().emit('leave', this.liveId)
      this.$socket().emit('join', this.liveId)
    })
  },
  beforeDestroy () {
    this.$socket().emit('leave', this.liveId)
    this.$socket().removeAllListeners()
    if (lazyLoad) { lazyLoad.destroy() }
    if (iframeConnector) {
      iframeConnector.stopTimelineHandling()
      iframeConnector.destroy()
    }
  },
  methods: {
    bindListeners () {
      /**
       * Posts
       */
      this.$socket().on(websockets.GET_ONE_POST, post => {
        this.$store.dispatch('posts/addPost', post)
        this.reloadEmbeds()
        setTimeout(() => {
          if (iframeConnector) { iframeConnector.loadAnchorsListeners() }
        }, 100)
      })
      this.$socket().on(websockets.DELETE_ONE_POST, post => {
        this.$store.commit('posts/DELETE_POST', post)
        this.reloadEmbeds()
      })
      this.$socket().on(websockets.UPDATE_ONE_POST, post => {
        this.$store.commit('posts/UPDATE_POST', post)
        this.reloadEmbeds()
      })
      this.$socket().on(websockets.TOGGLE_PIN_ONE_POST, post => {
        this.$store.dispatch('posts/togglePin', post)
        this.reloadEmbeds()
      })
      this.$socket().on(websockets.TOGGLE_HIGHLIGHT_ONE_POST, post => {
        this.$store.dispatch('posts/toggleHighlight', post)
        this.reloadEmbeds()
        setTimeout(() => {
          if (iframeConnector) { iframeConnector.loadAnchorsListeners() }
        }, 100)
      })

      /**
       * Live
       */
      this.$socket().on(websockets.UPDATE_ONE_LIVE, async live => {
        this.currentLive = { ...this.currentLive, ...live } // destructure to keep customization object
        if (iframeConnector) {
          // anchors
          iframeConnector.loadAnchorsListeners()

          // timeline
          iframeConnector.stopTimelineHandling()
          this.toggleTab(DisplayTabs.LIVE)
          if (this.timelineEnabled) { iframeConnector.startTimelineHandling() }
        }
      })

      /**
       * Surveys
       */
      this.$socket().on(websockets.RELOAD_SURVEYS, () => {
        this.$store.dispatch('survey/reloadSurveys')
      })

      /**
       * Scoreboard
       */
      if (this.currentLive.scoreboardEnabled) {
        this.$socket().on(websockets.GET_ONE_SCOREBOARD, result => {
          if (result) {
            this.scoreboard = result.data
            this.$socket().emit(websockets.GET_SCOREBOARD_ACTIONS, {
              scoreboardId: this.currentLive.scoreboardId,
              isPersonal: true
            })
          }
        })
        this.$socket().on(websockets.UPDATE_ONE_SCOREBOARD, result => {
          if (result) { this.scoreboard = result.data }
        })
        this.$socket().on(websockets.GET_SCOREBOARD_ACTIONS, result => {
          if (result) { this.scoreboardActions = result }
        })
        this.$socket().on(websockets.DELETE_ONE_SCOREBOARD_ACTION, () => {
          this.$socket().emit(websockets.GET_SCOREBOARD_ACTIONS, {
            scoreboardId: this.currentLive.scoreboardId,
            isPersonal: true
          })
        })

        this.$socket().emit(websockets.GET_ONE_SCOREBOARD, { scoreboardId: this.currentLive.scoreboardId })
      }
    },
    unbindListeners () {
      // unbind listeners except `joined`
      this.$socket().off(websockets.GET_ONE_SCOREBOARD)
      this.$socket().off(websockets.UPDATE_ONE_SCOREBOARD)
      this.$socket().off(websockets.GET_SCOREBOARD_ACTIONS)
      this.$socket().off(websockets.DELETE_ONE_SCOREBOARD_ACTION)
      this.$socket().off(websockets.GET_ONE_POST)
      this.$socket().off(websockets.DELETE_ONE_POST)
      this.$socket().off(websockets.UPDATE_ONE_POST)
      this.$socket().off(websockets.TOGGLE_PIN_ONE_POST)
      this.$socket().off(websockets.TOGGLE_HIGHLIGHT_ONE_POST)
      this.$socket().off(websockets.UPDATE_ONE_LIVE)
      this.$socket().off(websockets.RELOAD_SURVEYS)
    },
    async loadMorePosts () {
      if (!this.canLoadMorePosts) { return }

      this.isLoadingMorePosts = true
      this.paginationOffset = this.paginationOffset + PostsLimit
      await this.$store.dispatch('posts/fetchPosts', {
        liveId: this.liveId,
        formatImagesForLazyLoading: !this.disableLazyLoading,
        pagination: true,
        paginationOffset: this.paginationOffset,
        fetchHighlighted: false,
        fetchPinned: false
      })
      this.reloadEmbeds()
      this.optimizeVideos()
      this.isLoadingMorePosts = false
    },
    reloadEmbeds () {
      this.$nextTick(() => {
        if (!this.disableLazyLoading) { lazyLoad.reload() }
        this.$store.dispatch('lives/reloadEmbeds')
      })
    },
    toggleTab (tab) {
      this.activeTab = tab
      if (this.$refs['display']) { this.$refs['display'].calcDividerWidth(tab) }
      this.reloadEmbeds()
    },
    optimizeVideos () {
      this.customVideoOptimizerURLsPrefixes.forEach((pattern) => {
        window.VideoOptimizer(pattern)
      })
    }
  }
}
</script>
