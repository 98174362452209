import { Node } from 'tiptap'
import { nodeInputRule } from 'tiptap-commands'
import Instagram from './views/Instagram'

const INSTAGRAM_REGEX = /http(?:s)?:\/\/(?:www\.)?instagram\.com\/([a-zA-Z0-9_]+)/

const getSrc = src => {
  if (src.includes('blockquote')) {
    const regex = new RegExp(/href="(https:\/\/([\s\S]*?)\/(p|reel)\/([\s\S]*?)\/([\s\S]*?))"/)
    const link = regex.exec(src)[1]
    return link
  } else {
    return src
  }
}

export default class InstagramPlugin extends Node {
  get name () {
    return 'instagram'
  }

  get schema () {
    return {
      attrs: {
        class: {
          default: null
        },
        src: {
          default: null
        }
      },
      group: 'block',
      parseDOM: [
        {
          tag: 'instagram',
          getAttrs: dom => ({
            class: dom.getAttribute('class'),
            src: dom.getAttribute('src') ? dom.getAttribute('src') : dom.getAttribute('data-src')
          })
        }
      ],
      toDOM: node => [
        'instagram',
        { class: 'instagram-media', 'data-src': getSrc(node.attrs.src) },
        [
          'blockquote',
          { class: 'instagram-media', 'data-instgrm-permalink': getSrc(node.attrs.src) }
        ]
      ]
    }
  }

  commands ({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state
      const position = selection.$cursor
        ? selection.$cursor.pos
        : selection.$to.pos
      const node = type.create(attrs)
      const transaction = state.tr.insert(position, node)
      dispatch(transaction)
    }
  }

  inputRules ({ type }) {
    return [
      nodeInputRule(INSTAGRAM_REGEX, type, match => {
        const [, src] = match
        return {
          src
        }
      })
    ]
  }

  // return a Vue Component
  get view () {
    return Instagram
  }
}
