const ActionScoreboardTypes = {
  PRIMARY: 'primary', // Action in front-side scoreboard, showed without opening details
  SECONDARY: 'secondary' // Action in back-side scoreboard, for journalists
}

const ActionTabs = {
  ALL: 'all',
  FAVOURITES: 'favourites'
}

export { ActionScoreboardTypes, ActionTabs }
