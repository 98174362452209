import EmbedSurvey from '@/helpers/embeds/EmbedSurvey'

const openCreationModal = ({ commit }) => {
  commit('SET_TYPE', 'create')
  commit('SET_SURVEY_ID', null)
  commit('SET_IS_MODAL_OPEN', true)
}

const openStatsModal = ({ commit }, surveyId) => {
  commit('SET_TYPE', 'stats')
  commit('SET_SURVEY_ID', surveyId)
  commit('SET_IS_MODAL_OPEN', true)
}

const openUpdateModal = ({ commit }, surveyId) => {
  commit('SET_TYPE', 'edit')
  commit('SET_SURVEY_ID', surveyId)
  commit('SET_IS_MODAL_OPEN', true)
}

const reloadSurveys = async () => {
  const survey = new EmbedSurvey()
  survey.reload()
}

export default {
  openCreationModal,
  openStatsModal,
  openUpdateModal,
  reloadSurveys
}
