<template>
  <div class="dashboard-container">
    <aside class="settings-aside">
      <section class="settings-infos">
        <h2>{{ $t('app.liveSettings.categories.infos') }}</h2>
        <div class="settings-infos-block">
          <div class="settings-infos-block__item">
            <span :title="formatDate(dateStart, 'month').title">{{ formatDate(dateStart, 'month').date }}</span>
            <small>{{ $t('app.live.form.date') }}</small>
          </div>

          <div class="settings-infos-block__item">
            <span class="settings-flag">
              <span v-if="language" :class="`flag ida-flag-${language}`" />
              <span v-else>/</span>
            </span>
            <small>{{ $t('app.live.form.language') }}</small>
          </div>

          <div v-if="userName" class="settings-infos-block__item">
            <span :title="userName">{{ getInitials(userName) }}</span>
            <small>{{ $t('app.live.form.author') }}</small>
          </div>
        </div>
      </section>

      <section class="settings-options">
        <h2>{{ $t('app.liveSettings.categories.options') }}</h2>
        <ul class="settings-options">
          <li>
            <span>{{ $t('app.liveSettings.comments.enable') }}</span>
            <ida-switcher v-model="commentsEnabled" class="is-customized" active-color="#42A5F5" />
          </li>
          <li>
            <span>{{ $t('app.liveSettings.timeline.enable') }}</span>
            <ida-switcher
              v-model="timelineEnabled"
              class="is-customized"
              active-color="#42A5F5"
            />
          </li>
        </ul>
      </section>
    </aside>

    <main class="settings-main">
      <section class="settings-infos">
        <h2>{{ $t('app.liveSettings.categories.infos') }}</h2>

        <div class="settings-main__input settings-main__input--title">
          <label class="full">
            <h3>{{ $t('app.live.form.title.label') }}</h3>
            <ida-input
              id="settings-main__input--title"
              v-model="title"
              class="is-customized"
              :placeholder="$t('app.live.form.title.placeholder')"
              :data-tip-on-hover="$t('app.preview.update')"
            />
          </label>
        </div>

        <div class="settings-main__input settings-main__input--description">
          <h3>{{ $t('app.live.form.description.label') }}</h3>

          <div class="editor">
            <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
              <div class="menubar">
                <div class="menubar__left">
                  <button
                    v-tippy="{ html: '#tooltip' }"
                    class="menubar__button menubar__button--bold"
                    :class="{ 'is-active': isActive.bold() }"
                    :data-tip="$t('app.editor.menubar.bold')"
                    data-macos="Cmd + B"
                    data-windows="Ctrl + B"
                    @click="commands.bold"
                    @mouseover="updateTooltip"
                    @keyup.tab="updateTooltip"
                  >
                    <i class="ida-icon-bold" />
                  </button>

                  <button
                    v-tippy="{ html: '#tooltip' }"
                    class="menubar__button menubar__button--italic"
                    :class="{ 'is-active': isActive.italic() }"
                    :data-tip="$t('app.editor.menubar.italic')"
                    data-macos="Cmd + I"
                    data-windows="Ctrl + I"
                    @click="commands.italic"
                    @mouseover="updateTooltip"
                    @keyup.tab="updateTooltip"
                  >
                    <i class="ida-icon-italic" />
                  </button>

                  <button
                    v-tippy="{ html: '#tooltip' }"
                    class="menubar__button menubar__button--underline"
                    :class="{ 'is-active': isActive.underline() }"
                    :data-tip="$t('app.editor.menubar.underline')"
                    data-macos="Cmd + U"
                    data-windows="Ctrl + U"
                    @click="commands.underline"
                    @mouseover="updateTooltip"
                    @keyup.tab="updateTooltip"
                  >
                    <i class="ida-icon-underline" />
                  </button>

                  <button
                    v-tippy="{ html: '#tooltip' }"
                    class="menubar__button menubar__button--bulletList"
                    :class="{ 'is-active': isActive.bullet_list() }"
                    :data-tip="$t('app.editor.menubar.bulletList')"
                    @click="commands.bullet_list"
                    @mouseover="updateTooltip"
                    @keyup.tab="updateTooltip"
                  >
                    <i class="ida-icon-list" />
                  </button>

                  <button
                    v-tippy="{ html: '#tooltip' }"
                    class="menubar__button menubar__button--link"
                    :class="{ 'is-active': isActive.link() }"
                    :data-tip="$t('app.editor.menubar.link')"
                    @click="showPrompt(embedTypes.LINK)"
                    @mouseover="updateTooltip"
                    @keyup.tab="updateTooltip"
                  >
                    <i class="ida-icon-link" />
                  </button>
                </div>

                <div v-if="isEmbedModalOpen" class="embed__modal">
                  <EmbedModal :commands="commands" />
                </div>
              </div>
            </editor-menu-bar>

            <div class="content">
              <editor-content class="editor__content" :editor="editor" />
            </div>
          </div>
        </div>

        <div class="settings-main__input settings-main__input--date">
          <label class="centered">
            <h3>{{ $t('app.live.form.date') }}</h3>
            <div>
              <h4>{{ $t('app.live.form.dateStart') }}</h4>
              <v-date-picker
                id="settings-main__input--date"
                v-model="dateStart"
                mode="single"
                class="date__input"
                :popover="{ placement: 'bottom', visibility: 'focus' }"
              />
            </div>
          </label>
        </div>

        <div class="settings-main__input settings-main__input--lang">
          <label class="centered">
            <h3>{{ $t('app.live.form.language') }}</h3>
            <ida-dropdown centered class="is-customized">
              {{ language }}
              <span :class="`flag ida-flag-${language || 'fr'}`" />
              <template slot="dropdown">
                <ida-dropdown-item
                  v-for="(lang, index) in languages"
                  :key="index"
                  :value="index"
                  @select="(e) => handleSelect(e)"
                >
                  {{ lang }}
                  <span :class="`flag ida-flag-${lang}`" />
                </ida-dropdown-item>
              </template>
            </ida-dropdown>
          </label>
        </div>
      </section>

      <section class="settings-options">
        <h2>{{ $t('app.liveSettings.categories.options') }}</h2>

        <!-- <div class="settings-main__input">
            <label>
              {{ $t('app.live.form.online') }}
              <ul class="settings-options">
                <li>
                  <span>{{ $t('app.live.form.scoreboard.activate') }}</span>
                  <ida-switcher
                    v-model="scoreboardEnabled"
                    active-color="#42A5F5"
                  />
                </li>
              </ul>
            </label>
          </div> -->

        <div class="settings-main__input">
          <label>
            <h3>{{ $t('app.live.form.participation') }}</h3>
            <ul class="settings-options">
              <li>
                <span>{{ $t('app.liveSettings.comments.enable') }}</span>
                <ida-switcher v-model="commentsEnabled" class="is-customized" active-color="#42A5F5" />
              </li>
            </ul>
          </label>
        </div>

        <div class="settings-main__input">
          <label>
            <h3>{{ $t('app.liveSettings.timeline.title') }}</h3>
            <ul class="settings-options">
              <li>
                <span>
                  {{ $t('app.liveSettings.timeline.enable') }}
                </span>
                <ida-switcher
                  v-model="timelineEnabled"
                  class="is-customized"
                  active-color="#42A5F5"
                />
              </li>
            </ul>
          </label>
        </div>
      </section>

      <section class="settings-optionsAdvanced">
        <h2>{{ $t('app.liveSettings.categories.optionsAdvanced') }}</h2>

        <div class="settings-main__input">
          <label>
            <h3>
              {{ $t('app.liveSettings.videoPatterns.title') }}
              <tag type="success">{{ $t('app.generics.new') }}</tag>
            </h3>
            <ul class="settings-options">
              <li>
                <input-multiple
                  :tags="customVideoOptimizerURLsPrefixes"
                  :placeholder="$t('app.liveSettings.videoPatterns.placeholder')"
                  :validation-pattern="/^(http(s)?:\/\/)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/"
                  :error-message="$t('app.liveSettings.videoPatterns.error')"
                />
              </li>
            </ul>
          </label>
        </div>
      </section>

      <footer>
        <h2>{{ $t('app.liveSettings.categories.manage') }}</h2>

        <div class="settings-buttons">
          <ida-button type="primary" width="140" height="35" @click="updateLive">
            {{ $t('app.actions.save') }}
          </ida-button>
        </div>
      </footer>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Bold, Italic, Underline, BulletList, OrderedList, ListItem,
  Placeholder, History
} from 'tiptap-extensions'
import InputMultiple from '@/components/common/InputMultiple'
import Tag from '@/components/common/Tag'
import { embedTypes } from '@/helpers/constants/embeds'
import { formatDate } from '@/helpers/utils/date'
import { getInitials } from '@/helpers/utils/others'
import EmbedModal from '../Editor/EmbedModal'
import CustomLink from '../Editor/CustomPlugins/CustomLink'

export default {
  name: 'LiveSettings',
  components: {
    EditorContent,
    EditorMenuBar,
    EmbedModal,
    InputMultiple,
    Tag
  },
  data () {
    return {
      editor: new Editor({
        extensions: [
          new Bold(),
          new Italic(),
          new Underline(),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new History(),
          new Placeholder({ emptyNodeText: this.$t('app.live.form.description.placeholder') }),
          // custom tiptap plugins below
          new CustomLink()
        ],
        content: this.description,
        onUpdate: ({ getHTML }) => {
          this.description = getHTML()
        },
        parseOptions: {
          preserveWhitespace: true
        }
      }),
      hasChanges: false,
      languages: ['fr', 'en', 'es'],
      embedTypes
    }
  },
  computed: {
    ...mapGetters({
      userName: 'lives/currentUserName',
      isEmbedModalOpen: 'medias/isModalOpen',
      timelineEnabled: 'lives/currentTimelineEnabled',
      highlightedPostsHaveAtLeastOneEmptyTitle: 'posts/highlightedPostsHaveAtLeastOneEmptyTitle'
    }),
    currentLive: {
      get () { return this.$store.getters['lives/currentLive'] },
      set (live) { this.$store.commit('lives/SET_CURRENT_LIVE', live) }
    },
    currentHasChanges: {
      get () { return this.$store.getters['lives/currentHasChanges'] },
      set (boolean) { this.$store.commit('lives/SET_CURRENT_HAS_CHANGES', boolean) }
    },
    liveOnLoad: {
      get () { return this.$store.getters['lives/liveOnLoad'] },
      set (live) { this.$store.commit('lives/SET_LIVE_ON_LOAD', live) }
    },
    title: {
      get () { return this.$store.getters['lives/currentTitle'] },
      set (title) { this.$store.commit('lives/SET_CURRENT_TITLE', title) }
    },
    description: {
      get () { return this.$store.getters['lives/currentDescription'] },
      set (description) { this.$store.commit('lives/SET_CURRENT_DESCRIPTION', description) }
    },
    language: {
      get () { return this.$store.getters['lives/currentLanguage'] },
      set (language) { this.$store.commit('lives/SET_CURRENT_LANGUAGE', language) }
    },
    dateStart: {
      get () { return new Date(this.$store.getters['lives/currentDateStart']) },
      set (date) { this.$store.commit('lives/SET_CURRENT_DATE_START', date) }
    },
    commentsEnabled: {
      get () { return this.$store.getters['lives/currentCommentsEnabled'] },
      set (boolean) { this.$store.commit('lives/SET_CURRENT_COMMENTS_ENABLED', boolean) }
    },
    scoreboardEnabled: {
      get () { return this.$store.getters['lives/currentScoreboardEnabled'] },
      set (boolean) { this.$store.commit('lives/SET_CURRENT_SCOREBOARD_ENABLED', boolean) }
    },
    timelineEnabled: {
      get () { return this.$store.getters['lives/currentTimelineEnabled'] },
      set (boolean) { this.$store.commit('lives/SET_CURRENT_TIMELINE_ENABLED', boolean) }
    },
    customVideoOptimizerURLsPrefixes: {
      get () { return this.$store.getters['user/customVideoOptimizerURLsPrefixes'] },
      set (value) { this.$store.commit('user/SET_CUSTOM_VIDEO_OPTIMIZER_URLS_PREFIXES', value) }
    }
  },
  mounted () {
    this.detectChanges()
    this.editor.setContent(this.description)
  },
  updated () {
    this.detectChanges()
  },
  beforeDestroy () {
    if (this.editor) { this.editor.destroy() }
  },
  methods: {
    detectChanges () {
      // Fix: Compare only content of current live and liveOnLoad (which has no reactivity anymore)
      this.currentHasChanges = JSON.stringify(this.liveOnLoad) !== JSON.stringify(this.$store.getters['lives/currentLive'])
      if (this.currentHasChanges) window.onbeforeunload = () => { return confirm(this.$t('app.actions.leave.confirm')) }
      else window.onbeforeunload = null
    },
    getInitials (name) {
      return getInitials(name)
    },
    formatDate (date, type = 'short') {
      return formatDate(date, type)
    },
    handleSelect (index) {
      this.language = this.languages[index]
    },
    showPrompt (inputType) {
      this.$store.commit('medias/SET_TYPE', inputType)
      this.$store.commit('medias/TOGGLE_OPEN_MODAL', true)
    },
    updateLive () {
      // Refresh changes detection
      this.currentHasChanges = false
      window.onbeforeunload = null

      if (this.timelineEnabled && this.highlightedPostsHaveAtLeastOneEmptyTitle) {
        if (!confirm(this.$t('app.liveSettings.timeline.emptyHighlightedPostsTitles'))) { return }
      }

      this.$store.dispatch('lives/updateLive')
      this.$store.dispatch('user/updateCustomization')
      setTimeout(() => this.$router.push({ name: 'editLiveblog', params: { id: this.$route.params.id } }), 70)
    },
    updateTooltip (e) {
      this.$root.$emit('updateTooltip', e)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/settings.scss';
</style>

<style lang="scss">
.settings {
  .vc-w-full {
    width: inherit;
  }

  .ida-textarea > textarea {
    min-height: 50px;
    max-height: 200px;
  }
}
</style>
