const isModalOpen = state => state.isModalOpen
const isTwitter = state => state.type === 'twitter'
const modalError = state => state.modalError
const type = state => state.type
const videoOptimizationEnabled = state => state.videoOptimizationEnabled
const url = state => state.url

export default {
  isModalOpen,
  isTwitter,
  modalError,
  type,
  videoOptimizationEnabled,
  url
}
