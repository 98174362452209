<template>
  <div v-if="actions" class="dashboard-actions">
    <div class="dashboard-actions__header">
      <span
        v-for="action in actions"
        :key="action.id"
        :ref="action.id"
        :class="getActionNameClasses(action.id)"
        :data-id="action.id"
        :title="getActionName(action)"
        @click="toggleActionTab(action.id)"
      >
        {{ getFirstLetter(getActionName(action)) }}
      </span>
    </div>

    <div class="dashboard-actions__divider">
      <span ref="dashboardDivider" class="line" />
    </div>

    <div class="dashboard-actions__items">
      <div v-for="action in actions" :key="action.id">
        <div v-if="action.id === activeTab" class="dashboard-actions__column">
          <div :class="favouriteActionsClasses">
            <h2 class="dashboard-actions__cards-header" @click="handleTabClick('favourites')">
              {{ $t('app.live.action.tab.favourites') }}
              <i class="ida-icon-arrow-light" />
            </h2>

            <transition v-if="currentFavouritesActions" name="fade">
              <div v-show="isFavouriteActionsTabOpen" class="actions-container">
                <div
                  v-for="data in currentFavouritesActions"
                  :key="Math.random() + data.id"
                  class="action-shadow"
                >
                  <Action :action="data" :class="getActiveClasses(data)" />
                </div>
              </div>
            </transition>
            <transition v-else name="fade">
              <div v-show="isFavouriteActionsTabOpen" class="actions-favourite--isEmpty">
                <p>{{ $t('app.live.action.add.favourites') }} <i class="ida-icon-star-rounded" /></p>
              </div>
            </transition>
          </div>

          <div :class="actionsClasses">
            <h2 class="dashboard-actions__cards-header" @click="handleTabClick('all')">
              {{ $t('app.live.action.tab.actions') }}
              <i class="ida-icon-arrow-light" />
            </h2>

            <transition name="fade">
              <div v-show="isActionsTabOpen" class="actions-container">
                <div
                  v-for="data in action.actions"
                  :key="data.id"
                  class="action-shadow"
                >
                  <Action v-if="isActionNotSelected(data.id)" :action="data" :class="getActiveClasses(data)" />
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Action from '@/components/Action/ActionCard'
import { mapGetters } from 'vuex'
import { ActionTabs } from '@/constants/actions'
import { getFirstLetter } from '@/helpers/utils/others'

export default {
  name: 'ActionContainer',
  components: {
    Action
  },
  data () {
    return {
      activeTab: null
    }
  },
  computed: {
    ...mapGetters({
      actions: 'dashboardActions/actions',
      currentLive: 'lives/currentLive',
      currentFavouritesActions: 'lives/currentFavouritesActions',
      isActionsTabOpen: 'dashboardActions/isActionsTabOpen',
      isFavouriteActionsTabOpen: 'dashboardActions/isFavouriteActionsTabOpen'
    }),
    getActionName () {
      return action => action[this.$i18n.locale]
    },
    getActionNameClasses () {
      return actionId => [
        'dashboard-actions__header__item',
        'topic-card',
        { 'isActive': actionId === this.activeTab }
      ]
    },
    getActiveClasses () {
      return action => [
        { 'isActive': this.active && this.active[this.$i18n.locale] === action[this.$i18n.locale] }
      ]
    },
    getFirstLetter () {
      return actionName => getFirstLetter(actionName)
    },
    active: {
      get () { return this.$store.getters['dashboardActions/action'] },
      set (action) { this.$store.dispatch('dashboardActions/setAction', action) }
    },
    actionsClasses () {
      return [
        'dashboard-actions__cards',
        { 'dashboard-actions__cards--isOpen': this.isActionsTabOpen }
      ]
    },
    favouriteActionsClasses () {
      return [
        'dashboard-actions__cards',
        'dashboard-actions__cards--favourites',
        { 'dashboard-actions__cards--isOpen': this.isFavouriteActionsTabOpen }
      ]
    },
    isActionNotSelected () {
      return actionId => !this.currentLive.favouriteActions || (this.currentLive.favouriteActions && !this.currentLive.favouriteActions.includes(actionId))
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.actions && this.actions.length && this.activeTab === null) {
          const actionId = this.actions[0].id
          this.toggleActionTab(actionId)
        }
      }, 350)
    })
  },
  methods: {
    calcDividerWidth (index) {
      const parentClass = '.dashboard-actions__header'
      if (this.$refs[index] && this.$refs[index][0]) {
        const line = this.$refs[index][0].getBoundingClientRect()
        const parent = document.querySelector(parentClass).getBoundingClientRect()
        this.$refs.dashboardDivider.style.width = `${line.width}px`
        this.$refs.dashboardDivider.style.left = `calc(${line.left}px - ${parent.left}px)`
      }
    },
    handleTabClick (tab) {
      if (tab === ActionTabs.FAVOURITES) {
        this.$store.commit('dashboardActions/TOGGLE_FAVOURITE_ACTIONS_TAB')
      } else {
        this.$store.commit('dashboardActions/TOGGLE_ACTIONS_TAB')
      }
    },
    toggleActionTab (index) {
      this.activeTab = index
      this.calcDividerWidth(index)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/action.scss';
</style>
