import urls from '@/helpers/constants/urls'

const embedTypes = {
  CODE: 'code',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  LINK: 'link',
  TWITTER: 'twitter'
}

const scripts = [
  {
    name: 'survey',
    src: `${urls.foSurvey}/bundles/iframe/v2.js`,
    async: true
  },
  {
    name: 'twitter',
    src: 'https://platform.twitter.com/widgets.js',
    async: true,
    defer: true
  },
  {
    name: 'instagram',
    src: 'https://www.instagram.com/embed.js',
    async: true,
    defer: true
  }
]

export { embedTypes, scripts }
