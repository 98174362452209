<template>
  <div>
    <div class="preview-post" :data-id="comment.id">
      <div class="preview-post__header">
        <div class="preview-post__header__actions-container">
          <i
            v-tippy="{ html: '#tooltip' }"
            class="ida-icon-reply"
            :data-tip="$t('app.preview.comments.reply')"
            @click="openAnswerMode"
            @mouseover="updateTooltip"
            @keyup.tab="updateTooltip"
          />
          <i
            slot="reference"
            v-tippy="{ html: '#tooltip' }"
            class="ida-icon-bin-2"
            :data-tip="$t('app.preview.delete')"
            @mouseover="updateTooltip"
            @keyup.tab="updateTooltip"
            @click="deleteComment(comment.id)"
          />
        </div>

        <div class="preview-post__header__tags-container">
          <div class="preview-post__header__tag preview-post__header__tag--date">
            <span class="preview-post__header__publish-date" :title="formatDate(comment.createdAt).title">
              {{ formatDate(comment.createdAt).date }}
            </span>
          </div>

          <span class="preview-post__header__tag preview-post__header__tag--author">
            <svgicon name="people" original />
            {{ comment.author }}
          </span>
        </div>
      </div>

      <div class="preview-post__content" v-html="comment.content">
        {{ comment.content }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/utils/date'

export default {
  name: 'Comment',
  props: {
    comment: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatDate,
    async deleteComment (id) {
      await this.$store.dispatch('comments/deleteComment', id)
      this.recalculateDivider()
    },
    recalculateDivider () {
      setTimeout(() => {
        document.querySelector('.preview-header__item.isActive').click()
      }, 100)
    },
    openAnswerMode () {
      this.$root.$emit('answerComment', this.comment)
    },
    updateTooltip (e) {
      this.$root.$emit('updateTooltip', e)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/preview-post.scss';
</style>
