import Vue from 'vue'

import '@/plugins/Clipboard'
import '@/plugins/ElementUI'
import '@/plugins/IDalgoComponents'
import '@/plugins/Noty'
import '@/plugins/Svgicon'
import '@/plugins/TextareaAutosize'
import '@/plugins/Tippy'
import '@/plugins/VCalendar'
import '@/plugins/VideoOptimizer'

import '@/assets/stylesheets/main.scss'

import App from '@/App'
import { WebsocketAPI } from '@/api/WebsocketAPI'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/I18n'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  store.commit('FROMURL_UPDATED', from.name)

  if (!to.matched.some(record => record.meta.requiresAuth)) {
    next()
  } else if (store.getters['user/isLoggedIn']) {
    next()
  } else {
    store.dispatch('user/logout')
  }

  document.title = i18n.t('app.title')
})

const init = async () => {
  // Load user before app rendering
  if (!window.location.pathname.startsWith('/live')) {
    await store.dispatch('user/loadUser')
    window.wsAPI = new WebsocketAPI()
  }

  /* eslint-disable no-new */
  new Vue({
    el: '#idalgo-app',
    i18n,
    router,
    store,
    render: h => h(App)
  })
}
init()
