<template>
  <div class="home-container">
    <div class="home__header-container">
      {{ $t('app.live.welcome.title') }} {{ firstName }},
      <br>
      <span class="home-header-span">{{ $t('app.live.welcome.description') }} 📝✨</span>
    </div>
    <!-- <div class="home-filter-container">
      <div class="filter-item">
        <span class="filter-item-name">Date</span>
        <v-date-picker
          id="live__date"
          v-model="dates"
          mode="single"
          class="date__input"
          :popover="{ placement: 'bottom', visibility: 'focus' }"
        />
        <span class="filter-item-secondary">Aujourd'hui</span>
      </div>
      <div class="filter-item">
        <span class="filter-item-name">Type</span>
        <ida-dropdown>
          Tous
          <template slot="dropdown">
            <ida-dropdown-item
              value="0"
              @select="select"
            >
              Tous
            </ida-dropdown-item>
            <ida-dropdown-item
              value="1"
              @select="select"
            >
              Tous
            </ida-dropdown-item>
          </template>
        </ida-dropdown>
      </div>
      <div class="filter-item">
        <span class="filter-item-name">Auteur</span>
        <ida-dropdown>
          Tous
          <template slot="dropdown">
            <ida-dropdown-item
              value="0"
              @select="select"
            >
              Tous
            </ida-dropdown-item>
            <ida-dropdown-item
              value="1"
              @select="select"
            >
              Tous
            </ida-dropdown-item>
          </template>
        </ida-dropdown>
      </div>
    </div> -->
    <div class="home-tabs-search-container">
      <!-- <div class="home-tabs-container">
        <span
          v-for="tab in tabs"
          :key="tab.index"
          :ref="tab.index"
          class="home-tab"
          :class="{'isActive' : tab.index === activeTab}"
          @click="toggleActionTab(tab.index)"
        >{{ tab.title }}</span>
        <div class="home-tabs-container__divider">
          <span
            ref="dashboardDivider"
            class="home-line"
          />
        </div>
      </div> -->

      <!-- <ida-input
        value=""
        search-string-u-r-i="search"
        prefix-icon="ida-icon-search"
        clearable="true"
        search-color="#1367FB"
        @searchString="handleSearchString"
        @input="callback"
      /> -->
    </div>

    <div class="home__liveblog-list">
      <Loader v-if="isLoading" />
      <div v-else-if="hasLives">
        <ida-list
          :headers="[$t('app.live.list.header.title'), $t('app.live.list.header.date'), $t('app.live.list.header.journalist')]"
          uppercase
          :sizes="[70, 15, 15]"
        >
          <ida-list-item
            v-for="live in lives"
            :key="live.id"
            ref="lives"
            type="primary"
            rounded
            :content="[live.title, computeDate(live.dateStart).date, computeName(authors, live.userId)]"
            @click.native="$router.push({ name: 'editLiveblog', params: { id: live.id } })"
          />
        </ida-list>

        <div class="load-more">
          <Loader v-if="isLoadingMoreLives" size="small" />
          <ida-button v-else-if="canLoadMoreLives" type="primary" @click="loadMoreLives">
            {{ $t('app.actions.lives.loadMore') }}
          </ida-button>
        </div>
      </div>
      <span v-else>
        {{ $t('app.live.list.empty.text') }}
        <router-link :to="{ name: 'newLiveblog' }">{{ $t('app.live.list.empty.create') }}</router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LivesLimit } from '@/constants/pagination'
import websockets from '@/constants/websockets'
import { formatDate } from '@/helpers/utils/date'
import { getInitials } from '@/helpers/utils/others'

import Loader from '@/components/common/Loader'

export default {
  name: 'LiveList',
  components: { Loader },
  data () {
    return {
      authors: null,
      activeTab: 0,
      viewWidth: 0,
      tabs: [
        {
          index: 0,
          title: 'Tout'
        },
        {
          index: 1,
          title: 'En cours'
        },
        {
          index: 2,
          title: 'À venir'
        },
        {
          index: 3,
          title: 'Terminé'
        }
      ],
      dates: new Date(),
      isLoading: true,
      paginationOffset: 0,
      isLoadingMoreLives: false
    }
  },
  computed: {
    ...mapGetters({
      firstName: 'user/firstName',
      lives: 'lives/customerLives',
      hasLives: 'lives/customerHasLives',
      canLoadMoreLives: 'lives/canLoadMore'
    }),
    computeDate () {
      return date => formatDate(date, 'year')
    },
    computeName () {
      return (authors, userId) => {
        if (!authors || authors.length === 0) { return null }

        for (const author of authors) {
          if (author.userId === userId) { return getInitials(author.name) }
        }
      }
    }
  },
  async mounted () {
    this.$socket().on(websockets.GET_LIVES, async ({ lives, authors }) => {
      this.isLoading = false
      if (!lives) { return }

      if (lives.length < LivesLimit) { this.$store.commit('lives/SET_CAN_LOAD_MORE_LIVES', false) }
      await this.$store.commit('lives/ADD_LIVES', lives)
      this.authors = [
        ...this.authors || [],
        ...authors || []
      ]
      this.customizeList() // Override iDalgoComponents `ida-list-item`'s children styles
    })

    this.$socket().emit(websockets.GET_LIVES, { limit: LivesLimit, offset: 0 })
  },
  beforeDestroy () {
    this.$socket().off(websockets.GET_LIVES)
    this.$store.commit('lives/RESET_LIVES')
    this.authors = null
  },
  methods: {
    customizeList () {
      if (!this.$refs.lives) { return false }

      for (const item of this.$refs.lives) {
        const titleItem = item.$el.children[1]
        titleItem.classList.add('ida-list-item--title')

        const dateItem = item.$el.children[2]
        dateItem.classList.add('ida-list-item--date')

        const authorItem = item.$el.children[3]
        authorItem.classList.add('ida-list-item--author')
      }
    },
    toggleActionTab (index) {
      this.activeTab = index
      this.calcDividerWidth(index)
    },
    calcDividerWidth (index) {
      const line = this.$refs[index][0].getBoundingClientRect()
      this.$refs.dashboardDivider.style.width = `${line.width}px`
      this.$refs.dashboardDivider.style.left = `calc(${line.left}px - ${this.viewWidth}px)`
    },
    resizeViewport () {
      this.viewWidth = window.innerWidth / 100 * 8
      this.calcDividerWidth(this.activeTab)
    },
    async loadMoreLives () {
      if (!this.canLoadMoreLives) { return }

      this.isLoadingMoreLives = true
      this.paginationOffset = this.paginationOffset + LivesLimit
      this.$socket().emit(websockets.GET_LIVES, { limit: LivesLimit, offset: this.paginationOffset })
      this.isLoadingMoreLives = false
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/home.scss';
</style>
