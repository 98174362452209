<template>
  <div class="scoreboard-actions__container">
    <div class="scoreboard-actions__container--top">
      <div>
        <span>{{ $t('app.scoreboard.actions.actions') }}</span>
        <div class="scoreboard-actions__actions">
          <div
            v-for="(action, key) in scoreboardActionsSecondary"
            :key="key"
            class="scoreboard-actions__action"
            :class="{'is-active': activeAction.id === action.id}"
            @click="activeAction = action"
          >
            <div
              v-if="action.picto && action.scoreboard"
              class="picto"
              v-html="action.picto"
            />
          </div>
        </div>
      </div>

      <div>
        <span>{{ $t('app.scoreboard.actions.team') }}</span>
        <div class="scoreboard-actions__teams">
          <div
            ref="scoreboardTeam_1"
            class="scoreboard-actions__team"
            :class="{'is-active': activeTeam === scoreboardTeam1}"
            @click="activeTeam = scoreboardTeam1"
          >
            <img :src="scoreboardTeam1.imageUrl" alt="">
          </div>
          <div
            ref="scoreboardTeam_2"
            :class="{'is-active': activeTeam === scoreboardTeam2}"
            @click="activeTeam = scoreboardTeam2"
          >
            <img :src="scoreboardTeam2.imageUrl" alt="">
          </div>
        </div>
      </div>
      <div>
        <span>{{ $t('app.scoreboard.actions.time') }}</span>
        <input
          v-model="activeTime"
          type="text"
          class="scoreboard-actions__time"
          :placeholder="$t('app.scoreboard.actions.timePlaceholder')"
        >
      </div>
    </div>

    <div class="scoreboard-actions__container--bottom">
      <div class="scoreboard-actions__container__player-input">
        <span>{{ $t('app.scoreboard.actions.player') }}</span>
        <ida-guided-search
          ref="guidedSearchScoreboard"
          class="scoreboard-actions__guided-input"
          :selected-choice="activePlayer"
          :data-sources="dataSources"
          @inputChange="handleChange"
        />
      </div>
      <div class="scoreboard-actions__container__buttons">
        <div>
          <ida-button type="default" @click="handleClose">
            {{ $t('app.actions.delete.cancel') }}
          </ida-button>

          <ida-button
            class="scoreboard-save-action"
            type="primary"
            :disabled="isSaveButtonDisabled"
            @click="handleCreateScoreboardAction"
          >
            {{ $t('app.actions.save') }}
          </ida-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ActionScoreboardTypes } from '@/constants/actions'

export default {
  name: 'ScoreboardBOActions',
  computed: {
    ...mapGetters({
      currentLive: 'lives/currentLive',
      scoreboardTeam1: 'scoreboard/scoreboardTeam1',
      scoreboardTeam2: 'scoreboard/scoreboardTeam2',
      actions: 'dashboardActions/actions'
    }),
    dataSources () {
      return [{
        category: this.$t('app.editor.answer.category.player'),
        request: window.wsAPI.searchPersonByName,
        limit: 3
      }]
    },
    scoreboardActionsSecondary () {
      return this.$store.getters['dashboardActions/scoreboardActions'](ActionScoreboardTypes.SECONDARY)
    },
    activeAction: {
      get () { return this.$store.getters['scoreboard/activeAction'] },
      set (value) { this.$store.commit('scoreboard/SET_ACTIVE_ACTION', value) }
    },
    activePlayer: {
      get () { return this.$store.getters['scoreboard/activePlayer'] },
      set (value) { this.$store.commit('scoreboard/SET_ACTIVE_PLAYER', value) }
    },
    activeTeam: {
      get () { return this.$store.getters['scoreboard/activeTeam'] },
      set (value) { this.$store.commit('scoreboard/SET_ACTIVE_TEAM', value) }
    },
    activeTime: {
      get () { return this.$store.getters['scoreboard/activeTime'] },
      set (value) { this.$store.commit('scoreboard/SET_ACTIVE_TIME', value) }
    },
    isSaveButtonDisabled () {
      return !(
        Object.keys(this.activeAction).length &&
        Object.keys(this.activePlayer).length &&
        Object.keys(this.activeTeam).length &&
        this.activePlayer.value !== '' &&
        this.activeTime
      )
    }
  },
  methods: {
    handleChange (data) { this.activePlayer = data },
    handleCreateScoreboardAction () {
      if (this.activeAction && this.activeTeam && this.activePlayer && this.activeTime) {
        this.$store.dispatch('scoreboard/createScoreboardAction', {
          scoreboardId: this.currentLive.scoreboardId,
          actionId: this.activeAction.id,
          dataTeam: JSON.stringify(this.activeTeam),
          dataPlayer: JSON.stringify(this.activePlayer),
          time: this.activeTime
        })
      }
    },
    handleClose () {
      this.$store.commit('scoreboard/TOGGLE_IS_SCOREBOARD_OPEN')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/scoreboard/bo-scoreboard-actions.scss';
</style>
