import { Node } from 'tiptap'
import { nodeInputRule } from 'tiptap-commands'
import GlobalCode from './views/GlobalCode'

const GLOBAL_REGEX = /iframe|blockquote/

export default class GlobalCodePlugin extends Node {
  get name () {
    return 'code'
  }

  get schema () {
    return {
      attrs: {
        src: {
          default: null
        },
        videoOptimizationEnabled: {
          default: true
        }
      },
      group: 'block',
      parseDOM: [
        {
          tag: 'code',
          getAttrs: dom => ({
            src: dom.getAttribute('src') ? dom.getAttribute('src') : dom.getAttribute('data-src'),
            videoOptimizationEnabled: dom.getAttribute('videoOptimizationEnabled')
          })
        }
      ],
      toDOM: node => [
        'code',
        { class: 'global-code-post', 'data-src': node.attrs.src },
        ['div', { class: `global-code__post-container ${node.attrs.videoOptimizationEnabled ? 'is-video-optimized' : ''}` }, node.attrs.src]
      ]
    }
  }

  commands ({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state
      const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos

      if (attrs.src) { attrs.src = attrs.src.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/g, '') }

      const node = type.create(attrs)
      const transaction = state.tr.insert(position, node)
      dispatch(transaction)
    }
  }

  inputRules ({ type }) {
    return [
      nodeInputRule(GLOBAL_REGEX, type, match => {
        const [, src] = match
        return {
          src
        }
      })
    ]
  }

  get view () {
    return GlobalCode
  }
}
