import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  isModalOpen: false,
  modalError: false,
  videoOptimizationEnabled: false,
  type: '',
  url: ''
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
