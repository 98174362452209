import WebsocketClient from '../helpers/WebsocketClient'
import urls from '../helpers/constants/urls'

class WebsocketAPI {
  static SERVICE_PERSONS = 'Persons'
  static SERVICE_TEAMS = 'Teams'
  static SPORT_FOOTBALL = 'Football'
  static SPORT_RUGBY = 'Rugby'
  static SPORT_MULTISPORTS = 'Sport'

  constructor () {
    this.client = new WebsocketClient(urls.wsApi)
  }

  searchPersonByName = async ({ name }) => {
    if (name.length <= 3) {
      return new WebsocketResponse([])
    }

    try {
      const parameters = { name }
      const items = await this.client.get(WebsocketAPI.SERVICE_PERSONS, parameters)
      return new WebsocketResponse(items)
    } catch (e) {
      return new WebsocketResponse([])
    }
  }

  searchFootballTeamByName = async ({ name }) => {
    return this.searchTeamByName(name, WebsocketAPI.SPORT_FOOTBALL)
  }

  searchRugbyTeamByName = async ({ name }) => {
    return this.searchTeamByName(name, WebsocketAPI.SPORT_RUGBY)
  }

  searchMultisportsTeamByName = async ({ name }) => {
    return this.searchTeamByName(name, WebsocketAPI.SPORT_MULTISPORTS)
  }

  searchTeamByName = async (name, sport) => {
    if (name.length <= 3) {
      return new WebsocketResponse([])
    }

    try {
      const parameters = { name, sport }
      const items = await this.client.get(WebsocketAPI.SERVICE_TEAMS, parameters, sport)
      return new WebsocketResponse(items)
    } catch (e) {
      return new WebsocketResponse([])
    }
  }
}

class WebsocketResponse {
  constructor (data) {
    // Format response to make data usable in ida-guided-search component
    this.data = { data }
  }
}

export { WebsocketAPI, WebsocketResponse }
