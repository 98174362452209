<template>
  <div class="survey">
    <div
      v-if="isSurveyModalOpen"
      class="ida-msgbox"
      @click.self="handleAction('close')"
    >
      <div class="ida-msgbox-content">
        <i
          class="ida-msgbox-close ida-icon-cross-light"
          @click.self="handleAction('close')"
        />
        <div class="survey-iframe">
          <span />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import websockets from '@/constants/websockets'
import SurveyConnector from '@/helpers/connectors/SurveyConnector'
import urls from '@/helpers/constants/urls'

export default {
  name: 'Survey',
  data () {
    return {
      SurveyConnector: undefined
    }
  },
  computed: {
    isSurveyModalOpen: {
      get () { return this.$store.getters['survey/isModalOpen'] },
      set (boolean) { this.$store.commit('survey/SET_IS_MODAL_OPEN', boolean) }
    },
    type: {
      get () { return this.$store.getters['survey/type'] },
      set (type) { this.$store.commit('survey/SET_TYPE', type) }
    },
    surveyId: {
      get () { return this.$store.getters['survey/surveyId'] },
      set (surveyId) { this.$store.commit('survey/SET_SURVEY_ID', surveyId) }
    }
  },
  mounted () {
    document.body.addEventListener('keyup', e => {
      // Handle closing popup on Esc key press
      if (e.keyCode === 27) { this.handleAction('close') }
    })

    this.buildIframe()
  },
  beforeDestroy () {
    this.SurveyConnector.destroy()
  },
  methods: {
    buildIframe () {
      const url = {
        create: `${urls.boSurvey}/lite/surveys/new`,
        edit: `${urls.boSurvey}/lite/surveys/${this.surveyId}/edit`,
        stats: `${urls.boSurvey}/lite/surveys/${this.surveyId}/stats`
      }[this.type || 'create']

      const container = document.querySelector('.survey .survey-iframe > span')
      this.SurveyConnector = new SurveyConnector({
        container,
        url,
        getSurvey: this.getSurvey
      })
    },

    handleAction (action = 'close') {
      document.removeEventListener('keydown', this.keydownEvent)
      this.isSurveyModalOpen = false
    },

    getSurvey ({ surveyId, surveyCode }) {
      this.handleAction('close')

      if (!surveyId || !surveyCode) { return false }

      if (this.type === 'create') {
        this.$store.dispatch('posts/createSurvey', {
          surveyId,
          surveyCode,
          liveId: this.$route.params.id
        })
        this.$root.$emit('clear')
      } else if (this.type === 'edit') {
        // Reload surveys on display
        this.$socket().emit(websockets.RELOAD_SURVEYS)
        this.$store.dispatch('survey/reloadSurveys')
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/survey.scss';
</style>
