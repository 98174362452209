<template>
  <div class="embed__modal-container">
    <div class="input__label-group">
      <span class="input__icon">
        <svgicon v-if="isTwitterEmbed" name="x" class="ida-icon-twitter" original />
        <i v-else :class="`ida-icon-${type}`" />
      </span>
      <span>{{ $t(`app.medias.modal.label.${type}`) }}</span>
    </div>

    <div class="input-group">
      <ida-input
        ref="embedInput"
        v-model="src"
        class="modal_input"
        type="text"
      />
      <span v-if="modalError" class="embed_modal-error">
        {{ $t('app.medias.modal.error') }} ({{ type }})
      </span>
    </div>

    <ul class="embed__modal-options">
      <li v-if="isCodeEmbed">
        <span>{{ $t('app.medias.modal.label.enableVideoOptimization') }}</span>
        <ida-switcher
          v-model="videoOptimizationEnabled"
          class="small"
          active-color="#42A5F5"
        />
      </li>
    </ul>

    <div class="embed__modal-btn__group">
      <ida-button
        type="default"
        plain
        height="35"
        width="115"
        size="medium"
        @click.native="closeModal"
      >
        {{ $t('app.medias.modal.btn.cancel') }}
      </ida-button>
      <ida-button
        type="primary"
        plain
        height="35"
        width="115"
        size="medium"
        class="integrate-btn"
        @click="handleCommandClick(src)"
      >
        {{ $t('app.medias.modal.btn.integrate') }}
      </ida-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { embedTypes } from '@/helpers/constants/embeds'
import { isValidAnchor, isValidUrl } from '@/helpers/utils/url'

export default {
  name: 'EmbedModal',
  props: {
    commands: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      isTwitter: 'medias/isTwitter',
      type: 'medias/type',
      modalError: 'medias/modalError'
    }),
    // must be name src to match Tiptap plugin expectations
    src: {
      get () { return this.$store.getters['medias/url'] },
      set (value) { this.$store.commit('medias/SET_URL', value) }
    },
    videoOptimizationEnabled: {
      get () { return this.$store.getters['medias/videoOptimizationEnabled'] },
      set (value) { this.$store.commit('medias/SET_VIDEO_OPTIMIZATION_ENABLED', value) }
    },
    isCodeEmbed () {
      return this.type === embedTypes.CODE
    },
    isTwitterEmbed () {
      return this.type === embedTypes.TWITTER
    }
  },
  mounted () {
    // Handle closing popup on Esc key press
    this.$refs.embedInput.focus()
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.$store.commit('medias/TOGGLE_OPEN_MODAL', false)
      }
    })

    document.body.addEventListener('keyup', this.handleEnterKeyboard)
  },
  beforeDestroy () {
    document.body.removeEventListener('keyup', this.handleEnterKeyboard)
  },
  methods: {
    handleEnterKeyboard (e) {
      if (e.keyCode === 13) {
        if (this.src !== null) {
          this.handleCommandClick(this.src)
        }
      }
    },
    closeModal () {
      this.$store.commit('medias/TOGGLE_OPEN_MODAL', false)
      this.$store.commit('medias/RESET_URL')
      this.$store.commit('medias/SET_ERROR', false)
      this.videoOptimizationEnabled = false
    },
    handleCommandClick (src) {
      if (this.type === embedTypes.CODE) {
        if (src.includes('blockquote') || src.includes('iframe')) {
          this.commands.code({ src, videoOptimizationEnabled: this.videoOptimizationEnabled })
          this.$store.commit('medias/TOGGLE_OPEN_MODAL', false)
          this.$store.commit('medias/RESET_URL')
          this.$store.commit('medias/SET_ERROR', false)
          this.videoOptimizationEnabled = false
        } else {
          this.$store.commit('medias/RESET_URL')
          this.$store.commit('medias/SET_ERROR', true)
        }
      } else if (this.type === embedTypes.LINK) {
        if (src !== null && (isValidUrl(src) || isValidAnchor(src))) {
          this.commands.link({ href: src })
          this.$store.commit('medias/TOGGLE_OPEN_MODAL', false)
          this.$store.commit('medias/RESET_URL')
          this.$store.commit('medias/SET_ERROR', false)
        } else {
          this.$store.commit('medias/RESET_URL')
          this.$store.commit('medias/SET_ERROR', true)
        }
      } else {
        if (src.includes(`${this.type}.com/`) || (this.type === embedTypes.TWITTER && src.includes('x.com/'))) {
          switch (this.type) {
            case embedTypes.TWITTER:
              this.commands.twitter({ src })
              break
            case embedTypes.INSTAGRAM:
              this.commands.instagram({ src })
              break
            case embedTypes.FACEBOOK:
              this.commands.facebook({ src })
              break
            default:
              break
          }

          this.$store.commit('medias/TOGGLE_OPEN_MODAL', false)
          this.$store.commit('medias/SET_ERROR', false)
          this.$store.commit('medias/RESET_URL')
        } else {
          this.$store.commit('medias/RESET_URL')
          this.$store.commit('medias/SET_ERROR', true)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/embed-modal.scss';
</style>
