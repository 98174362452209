import getters from './getters'
import mutations from './mutations'

const state = {
  mode: null,
  isSaving: false
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
