<template>
  <div class="layout layout--default">
    <Topbar v-if="topbar" />

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <div id="tooltip" v-tippy-html class="hidden">
      {{ tooltip.text }}
      <span v-if="tooltip.shortcut" class="title__shortcut">{{ tooltip.shortcut }}</span>
    </div>
  </div>
</template>

<script>
import Topbar from '@/components/Topbar'
import { Platforms } from '@/constants/userAgents'
import websockets from '@/constants/websockets'
import { getUserAgent } from '@/helpers/utils/window'

export default {
  components: {
    Topbar
  },
  props: {
    topbar: {
      type: Boolean,
      default: true
    },
    breadcrumb: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tooltip: {
        text: '',
        shortcut: null
      }
    }
  },
  created () {
    if (process.env.NODE_ENV === 'production') { require('@/plugins/Hotjar') }
  },
  mounted () {
    this.$root.$on('updateTooltip', this.updateTooltip)
    this.$socket().on(websockets.NOTY_ERROR, ({ translation }) => this.$noty.error(this.$t(translation)))
    this.$socket().on(websockets.NOTY_SUCCESS, ({ translation }) => this.$noty.success(this.$t(translation)))
  },
  methods: {
    updateTooltip (e) {
      const data = e.target.closest('[data-tip]') || e.target
      if (data.getAttribute('data-tip')) {
        const os = getUserAgent().os
        let shortcut = null

        if (os === Platforms.MACOS && data.getAttribute('data-macos')) {
          shortcut = data.getAttribute('data-macos')
        } else if (os === Platforms.WINDOWS && data.getAttribute('data-windows')) {
          shortcut = data.getAttribute('data-windows')
        }

        setTimeout(() => {
          this.tooltip.text = data.getAttribute('data-tip')
          this.tooltip.shortcut = shortcut
        }, 100)
      }
    }
  }
}
</script>

<style lang="scss">
.dashboard {
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 4vw;
    padding-right: 4vw;
    height: 100%;

    > *:not(:first-child) {
      margin-left: 50px;
    }
  }
}
</style>
