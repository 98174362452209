import urls from '@/helpers/constants/urls'

class EmbedSurvey {
  constructor () {
    this.exists = !!document.querySelector('.iDalgo-survey')
  }

  async check () {
    while (!document.IdalgoSurvey) {
      await new Promise((resolve, reject) => setTimeout(resolve, 50))
    }
  }

  async reload () {
    this.reset()

    if (this.exists) {
      await this.check()
      // document.IdalgoSurvey.loadedSurveys = []
      document.IdalgoSurvey.load()
    }
  }

  reset () {
    document.querySelectorAll(`iframe[src^="${urls.foSurvey}"]`).forEach(e => e.remove())
  }
}

export default EmbedSurvey
