<template>
  <div>
    <LiveList />
  </div>
</template>

<script>
import LiveList from '@/components/Home/LiveList'

export default {
  components: {
    LiveList
  }
}
</script>
