const customization = state => state.customization
/* colors */
const customColorPrimary = state => state.customization && state.customization.colors && state.customization.colors.primary
const customColorText = state => state.customization && state.customization.colors && state.customization.colors.text
const customColorSecondary = state => state.customization && state.customization.colors && state.customization.colors.secondary
const customColorBorders = state => state.customization && state.customization.colors && state.customization.colors.borders
const customColorTimeline = state => state.customization && state.customization.colors && state.customization.colors.timeline
const customColorBackground = state => state.customization && state.customization.colors && state.customization.colors.background
/* settings */
const customVideoOptimizerURLsPrefixes = state => state.customization && state.customization.settings && state.customization.settings.videoOptimizerURLsPrefixes
/* user */
const firstName = state => state.user && state.user.firstName
const fullName = state => state.user
  ? state.user.firstName === state.user.lastName
    ? `${state.user.firstName}`
    : `${state.user.firstName} ${state.user.lastName}`
  : null
const isLoggedIn = state => !!state.user.id
const token = state => state.user && state.user.token
const user = state => state.user
const customer = state => state.user && state.user.customer

export default {
  customization,
  customColorPrimary,
  customColorText,
  customColorSecondary,
  customColorBorders,
  customColorTimeline,
  customColorBackground,
  customVideoOptimizerURLsPrefixes,
  customer,
  firstName,
  fullName,
  isLoggedIn,
  token,
  user
}
