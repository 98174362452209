<template>
  <div class="dashboard settings">
    <div v-if="isEmbedModalOpen" class="embed__background" @click="closeModal" />
    <Header />
    <LiveSettings v-if="isMounted" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import liveblogAPI from '@/api/LiveblogAPI'
import Header from '@/components/Header'
import LiveSettings from '@/components/Settings/LiveSettings'

export default {
  components: {
    Header,
    LiveSettings
  },
  data () {
    return {
      liveId: this.$route.params.id,
      isMounted: false
    }
  },
  computed: {
    ...mapGetters({
      currentHasChanges: 'lives/currentHasChanges',
      isEmbedModalOpen: 'medias/isModalOpen'
    })
  },
  beforeCreate: function () {
    document.body.classList.add('components-customized')
  },
  async mounted () {
    try {
      const { live } = await liveblogAPI.fetchLive(this.liveId)
      this.$store.dispatch('lives/setCurrentLive', { live })
      this.$store.commit('lives/SET_LIVE_ON_LOAD', Object.assign({}, live)) // Remove reactivity
      this.isMounted = true
    } catch (e) {
      return this.$router.push({ path: '/' })
    }
  },
  beforeDestroy () {
    setTimeout(() => document.body.classList.remove('components-customized'), 250) // To prevent CSS transitions problems, wait for page to fade out
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'liveblogSettings' && this.currentHasChanges) {
      if (confirm(this.$t('app.actions.leave.confirm'))) { next() }
    } else next()
  },
  methods: {
    closeModal () {
      this.$store.commit('medias/TOGGLE_OPEN_MODAL', false)
      this.$store.commit('medias/RESET_URL')
      this.$store.commit('medias/SET_ERROR', false)
    }
  }
}
</script>
