<template>
  <div class="display-container">
    <DisplayHeader />

    <CommentForm />

    <div class="display-content">
      <Timeline v-if="timelineEnabled" />

      <div class="liveblog-content">
        <!-- Tabs -->
        <div v-if="!timelineEnabled" class="display-tabs">
          <div class="display-tabs__items">
            <div
              class="display-tabs__item display-tabs__item--live"
              :class="{isActive: activeTab === DisplayTabs.LIVE}"
              @click="toggleTab(DisplayTabs.LIVE)"
            >
              <span :ref="DisplayTabs.LIVE" class="display-tabs__item-title">
                {{ $t('app.display.tabs.live') }}
              </span>
            </div>
            <div
              class="display-tabs__item display-tabs__item--highlighted"
              :class="{isActive: activeTab === DisplayTabs.HIGHLIGHTS}"
              @click="toggleTab(DisplayTabs.HIGHLIGHTS)"
            >
              <span :ref="DisplayTabs.HIGHLIGHTS" class="display-tabs__item-title">
                {{ $t('app.display.tabs.highlighted') }}
              </span>
              <span
                v-if="hasHighlighted"
                class="small-bubble small-bubble--highlighted"
              >{{ highlightedPosts.length }}</span>
            </div>
          </div>

          <div class="display-tabs__divider">
            <span ref="liveDivider" class="line" />
          </div>
        </div>

        <!-- Live tab -->
        <div v-if="activeTab === DisplayTabs.LIVE" class="posts-container">
          <!-- Pinned post -->
          <div v-if="pinnedPost" class="posts-container__pinned">
            <span class="posts-container__pinned__banner">
              <div class="posts-container__pinned__banner-title">
                <i class="ida-icon-pin-filled" />
                <span>{{ $t('app.display.pinned') }}</span>
              </div>
            </span>
            <Post :post="pinnedPost" />
          </div>

          <!-- Posts -->
          <Loader v-if="!posts" />
          <div v-else-if="hasPosts" class="posts-container__classic">
            <span class="display-container-divider" />
            <Post
              v-for="(post, key) in posts"
              :key="key"
              :post="post"
              :active-tab="activeTab"
            />
          </div>
          <div v-else class="posts-container__error">
            <span>{{ $t('app.display.empty') }}</span>
          </div>

          <div class="posts-container-more">
            <Loader v-if="isLoadingMorePosts" />
            <ida-button v-else-if="canLoadMorePosts && hasPosts" type="primary" @click="loadMorePosts">
              {{ $t('app.actions.posts.loadMore') }}
            </ida-button>
          </div>
        </div>

        <!-- Highlighted tab -->
        <div v-if="activeTab === DisplayTabs.HIGHLIGHTS" class="posts-container">
          <Loader v-if="!highlightedPosts" />
          <div
            v-else-if="hasHighlighted"
            class="posts-container__classic"
          >
            <span class="display-container-divider" />
            <Post
              v-for="(post, key) in highlightedPosts"
              :key="key"
              :post="post"
            />
          </div>
          <div v-else class="posts-container__error">
            <span>{{ $t('app.preview.highlighted.empty') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DisplayTabs } from '@/constants/display'

import Loader from '@/components/common/Loader'
import CommentForm from './CommentForm'
import DisplayHeader from './DisplayHeader'
import Post from './Post'
import Timeline from './Timeline'

export default {
  name: 'Display',
  components: {
    Loader,
    Post,
    DisplayHeader,
    CommentForm,
    Timeline
  },
  props: {
    activeTab: {
      type: String,
      default: DisplayTabs.LIVE
    },
    toggleTab: {
      type: Function,
      default: null
    },
    loadMorePosts: {
      type: Function,
      default: null
    },
    isLoadingMorePosts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      DisplayTabs
    }
  },
  computed: {
    ...mapGetters({
      posts: 'posts/posts',
      hasPosts: 'posts/hasPosts',
      hasHighlighted: 'posts/hasHighlighted',
      canLoadMorePosts: 'posts/canLoadMore',
      pinnedPost: 'posts/pinnedPost',
      highlightedPosts: 'posts/highlightedPosts',
      highlightedPostsOnly: 'posts/highlightedPostsOnly',
      commentsEnabled: 'lives/currentCommentsEnabled',
      timelineEnabled: 'lives/currentTimelineEnabled'
    }),
    author: {
      get () { return this.$store.getters['comments/commentAuthor'] },
      set (author) { this.$store.commit('comments/SET_AUTHOR', author) }
    },
    content: {
      get () { return this.$store.getters['comments/commentContent'] },
      set (content) { this.$store.commit('comments/SET_CONTENT', content) }
    }
  },
  mounted () {
    setTimeout(() => { this.calcDividerWidth(this.activeTab) }, 300)
    window.addEventListener('resize', this.calcDividerWidth(this.activeTab))
  },
  methods: {
    calcDividerWidth (tab) {
      if (this.timelineEnabled) { return }

      const parentClass = '.display-tabs'
      if (this.$refs[tab]) {
        const line = this.$refs[tab].getBoundingClientRect()
        const parent = document.querySelector(parentClass).getBoundingClientRect()
        this.$refs.liveDivider.style.width = `${line.width}px`
        this.$refs.liveDivider.style.left = `calc(${line.left}px - ${parent.left}px)`
      }
    },
    sendComment () {
      if (this.content === '') {
        return document.querySelector('textarea#comment').reportValidity() // Fix: Textarea (really) required
      }
      this.$store.dispatch('comments/createComment', this.$route.params.id)
      this.$store.dispatch('comments/resetComment')
    },
    showCommentForm () {
      this.$store.commit('comments/SET_IS_CREATED', false)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/display/display.scss';

.display-content {
  display: flex;

  > div {
    width: 100%;
  }

  @media (max-width: 600px) {
    display: block;
  }
}

// make sure liveblog content get splitted when timeline on desktop is enabled
@media (min-width: 601px) {
  .liveblog-timeline + .liveblog-content {
    margin-left: 200px;
    width: calc(100% - 200px) !important;
  }
}
</style>
