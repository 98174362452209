<template>
  <div :class="tagClasses">
    <span ref="value" class="tag-text"><slot /></span>

    <div v-if="removable" class="tag-close" @click="handleRemoveClick">
      <svgicon name="close" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'success'].indexOf(value) !== -1
    },
    removable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tagClasses () {
      return [
        'tag',
        `tag--${this.type}`,
        { 'tag--removable': this.removable }
      ]
    }
  },
  methods: {
    handleRemoveClick () {
      const value = this.$refs.value.innerHTML.toLowerCase().trim()
      this.$emit('remove', value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/variables.scss';

.tag {
  display: inline-block;
  font-weight: bold;
  font-size: 13px;
  margin-left: 4px;
  border-radius: 4px;
  padding: 3px 6px;
  background: $--color-border;
  color: grey;

  &--success {
    background: $--color-success;
    color: white;
  }

  &--removable {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #DDD;

    svg {
      width: 8px;
      height: 8px;

      path {
        fill: black;
      }
    }

    .tag-close {
      cursor: pointer;
      display: flex; // required for Firefox
    }
  }
}
</style>
