<template>
  <div class="code-container" v-html="src" />
</template>

<script>
export default {
  name: 'GlobalCode',
  props: ['node'],
  computed: {
    src () {
      let src = this.node && this.node.attrs.src
      return src && src.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/g, '')
    }
  }
}
</script>
