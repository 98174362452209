<template>
  <div :class="topicClasses" @click="handleClick(topic.id)">
    <span :class="topicStatusClasses"><i class="ida-icon-check" /></span>

    <div class="topic__content">
      <div class="topic__icon" :style="{ backgroundColor: topic.color }">
        <div v-if="topic.icon" class="picto" v-html="topic.icon" />
        <i v-else class="ida-icon-liveblog" />
      </div>
      <span class="topic__name">{{ topicName }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { slugify } from '@/helpers/utils/text'
// import urls from '@/helpers/constants/urls'

export default {
  name: 'TopicCard',
  props: {
    topic: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    ...mapGetters({
      topics: 'lives/topics'
    }),
    topicClasses () {
      return [
        'topic',
        `topic--${slugify(this.topic[this.$i18n.locale])}`,
        { 'isActive': this.topics && this.topics.indexOf(this.topic.id) !== -1 }
      ]
    },
    topicName () {
      return this.topic[this.$i18n.locale]
    },
    topicStatusClasses () {
      return [
        'topic__status',
        { 'topic__status--multiple': this.topics && this.topics.length }
      ]
    }
  },
  methods: {
    handleClick (topicId) {
      this.$store.dispatch('lives/addNewTopic', topicId)

      // Stringify topics and update `topics` cookie
      // const topics = this.$store.getters['lives/topics']
      // const topicsStringified = JSON.stringify(topics)
      // Cookies.set('topics', topicsStringified, { domain: urls.cookieDomain })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/topic-card.scss';
</style>
