<template>
  <div class="live__form-container">
    <div class="live__back-link-container" @click="$router.push({ name: 'newLiveblog' })">
      <i class="ida-icon-arrow-light" />
      <span class="live__back-link">{{ $t('app.live.back') }}</span>
    </div>

    <h1>{{ $t('app.live.title') }}</h1>
    <h2>{{ $t('app.live.subtitle') }}</h2>

    <form method="post">
      <div class="live__form">
        <div class="live__form-input__container">
          <div class="live__form__group">
            <div class="live__input left">
              <label class="live__input-label title">
                {{ $t('app.live.form.title.label') }}
                <ida-input
                  id="live__title"
                  v-model="title"
                  :class="{'input-error' : isTitleError}"
                  :placeholder="$t('app.live.form.title.placeholder')"
                />
                <span v-if="isTitleError" class="input-error-label">
                  {{ $t('app.live.create.title.error') }}
                </span>
              </label>

              <label class="live__input-label description">
                {{ $t('app.live.form.description.label') }}
                <ida-input
                  v-model="description"
                  :placeholder="$t('app.live.form.description.placeholder')"
                  type="textarea"
                />
              </label>

              <div v-if="showScoreboardInput" class="live__input">
                <label class="live__input-label">
                  {{ $t('app.live.form.scoreboard.activate') }}
                  <ida-switcher
                    v-model="scoreboardEnabled"
                    class="js-toggle-scoreboard-enabled"
                    active-color="#42A5F5"
                  />
                </label>
              </div>
              <Scoreboard v-if="showScoreboard" />
            </div>
          </div>
          <div class="live__form__group">
            <div class="live__input">
              <label class="live__input-label">
                {{ $t('app.live.form.date') }}
                <v-date-picker
                  id="live__date"
                  v-model="dateStart"
                  mode="single"
                  class="date__input"
                  :popover="{ placement: 'bottom', visibility: 'focus' }"
                  :class="{'input-error' : isDateError}"
                />
                <span v-if="isDateError" class="input-error-label">
                  {{ $t('app.live.create.date.error') }}
                </span>
              </label>
            </div>

            <div class="live__input">
              <label class="live__input-label">
                {{ $t('app.live.form.language') }}
                <ida-dropdown
                  id="live__language"
                  class="live__language"
                  :class="{'input-error' : isLanguageError}"
                  centered
                >
                  <span :class="`flag ida-flag-${selectedChoice.title}`" />
                  {{ selectedChoice.title }}
                  <template slot="dropdown">
                    <ida-dropdown-item
                      v-for="(choice, key) in choices"
                      :key="key"
                      class="live__language__item"
                      :value="choice.index"
                      @select="(e) => handleSelect(e)"
                    >
                      <span :class="`flag ida-flag-${choice.title}`" />
                      {{ choice.title }}
                    </ida-dropdown-item>
                  </template>
                </ida-dropdown>
                <span v-if="isLanguageError" class="input-error-label">
                  {{ $t('app.live.create.language.error') }}
                </span>
              </label>
            </div>

            <div class="live__input">
              <label class="live__input-label">
                {{ $t('app.live.form.comments.activate') }}
                <ida-switcher
                  v-model="commentsEnabled"
                  class="js-toggle-comments-enabled"
                  active-color="#42A5F5"
                />
              </label>
            </div>

            <div class="live__input">
              <label class="live__input-label">
                {{ $t('app.live.form.timeline.activate') }}
                <ida-switcher v-model="timelineEnabled" active-color="#42A5F5" />
              </label>
            </div>

            <ida-button type="primary" class="confirm-btn" @click="createLive">
              {{ $t('app.live.form.create') }}
            </ida-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scoreboard from '@/components/Scoreboard/Scoreboard'
import websockets from '@/constants/websockets'
import { Topics } from '../../constants/topics'

export default {
  name: 'LiveForm',
  components: {
    Scoreboard
  },
  data () {
    return {
      dates: new Date(),
      choices: [
        {
          index: 0,
          title: 'fr'
        },
        {
          index: 1,
          title: 'en'
        },
        {
          index: 2,
          title: 'es'
        }
      ],
      selectedChoice: {
        index: 0,
        title: 'fr'
      }
    }
  },
  computed: {
    ...mapGetters({
      liveTopics: 'lives/topics',
      isSingleTopic: 'lives/isSingleTopic',
      isTitleError: 'lives/isTitleError',
      isLanguageError: 'lives/isLanguageError',
      isDateError: 'lives/isDateError',
      scoreboard: 'scoreboard/scoreboard'
    }),
    title: {
      get () { return this.$store.getters['lives/title'] },
      set (title) { this.$store.commit('lives/SET_TITLE', title) }
    },
    description: {
      get () { return this.$store.getters['lives/description'] },
      set (description) { this.$store.commit('lives/SET_DESCRIPTION', description) }
    },
    language: {
      get () { return this.$store.getters['lives/language'] },
      set (language) { this.$store.commit('lives/SET_LANGUAGE', language) }
    },
    dateStart: {
      get () { return this.$store.getters['lives/dateStart'] },
      set (date) { this.$store.commit('lives/SET_DATE_START', date) }
    },
    commentsEnabled: {
      get () { return this.$store.getters['lives/commentsEnabled'] },
      set (boolean) { this.$store.commit('lives/SET_COMMENTS_ENABLED', boolean) }
    },
    scoreboardEnabled: {
      get () { return this.$store.getters['lives/scoreboardEnabled'] },
      set (boolean) { this.$store.commit('lives/SET_SCOREBOARD_ENABLED', boolean) }
    },
    showScoreboardInput () {
      const authorizedTopicsIds = Object.values(Topics)
        .filter(topic => topic.canCreateScoreboard)
        .map(topic => topic.id)
      if (this.isSingleTopic && authorizedTopicsIds.includes(this.liveTopics[0])) { return true }
      this.$store.commit('lives/SET_SCOREBOARD_ENABLED', false)
      return false
    },
    showScoreboard () {
      return this.showScoreboardInput && this.scoreboardEnabled
    },
    timelineEnabled: {
      get () { return this.$store.getters['lives/timelineEnabled'] },
      set (boolean) { this.$store.commit('lives/SET_TIMELINE_ENABLED', boolean) }
    }
  },
  watch: {
    selectedChoice () {
      this.language = this.selectedChoice.title
    }
  },
  async mounted () {
    this.$store.dispatch('lives/resetForm')

    this.$socket().on(websockets.GET_ONE_LIVE, live => {
      return this.$router.push({ name: 'editLiveblog', params: { id: live.id } })
    })

    this.$socket().on(websockets.GET_ONE_SCOREBOARD, result => {
      this.$store.commit('lives/SET_SCOREBOARD_ID', result.id)
      return this.$store.dispatch('lives/createLive')
    })
  },
  beforeDestroy () {
    this.$socket().off(websockets.GET_ONE_SCOREBOARD)
  },
  methods: {
    handleSelect (key, type) {
      this.selectedChoice = this.choices[key]
    },
    async createLive () {
      if (!this.title || !this.language || !this.dateStart) {
        this.$store.commit('lives/SET_LIVE_LOAD', false)
        return this.$noty.error(this.$t('app.notifications.live.creation_failed'))
      }

      if (!this.scoreboardEnabled) {
        return this.$store.dispatch('lives/createLive')
      }

      if (!this.scoreboard.team_1.value || !this.scoreboard.team_2.value) {
        return this.$noty.error(this.$t('app.notifications.scoreboard.creation_failed'))
      }

      this.$store.dispatch('scoreboard/createScoreboard')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/liveform.scss';
</style>
