import VanillaLazyLoad from 'vanilla-lazyload'

class LazyLoad {
  constructor () {
    this.instance = new VanillaLazyLoad({ elements_selector: '.lazy' })
  }

  reload () {
    this.instance.update()
  }

  destroy () {
    this.instance.destroy()
  }
}

export default new LazyLoad()
