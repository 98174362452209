<template>
  <div
    v-if="JSON.parse(action.dataTeam).value === team.value"
    class="scoreboard-action__resume--action"
  >
    <div class="scoreboard__resume_action-manage">
      <i
        v-tippy="{ html: '#tooltip' }"
        class="ida-icon-cross-light"
        :data-tip="$t('app.preview.delete')"
        @click="handleDeleteAction(action.id)"
        @mouseover="updateTooltip"
      />
      <!-- <i
        v-tippy="{ html: '#tooltip' }"
        class="ida-icon-edit"
        :data-tip="$t('app.preview.update')"
        @mouseover="updateTooltip"
      /> -->
    </div>
    <div class="scoreboard__resume_action-time">
      {{ action.time }}
    </div>
    <div class="scoreboard__resume_action-player">
      <span :title="playerName">{{ playerName }}</span>
      <div class="picto-container">
        <div
          class="picto"
          v-html="actionsScoreboard(action.actionId)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ScoreboardBOResumeAction',
  props: {
    action: {
      type: Object,
      default: null
    },
    team: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      actions: 'dashboardActions/actions'
    }),
    actionsScoreboard () {
      return data => {
        if (this.actions && this.actions.length) {
          for (const action of this.actions[0].actions) {
            if (action.id === data) {
              return action.picto
            }
          }
        }
      }
    },
    playerName () {
      return this.action.dataPlayer && JSON.parse(this.action.dataPlayer).value
    }
  },
  methods: {
    handleDeleteAction (id) {
      this.$store.dispatch('scoreboard/deleteScoreboardAction', id)
    },
    updateTooltip (e) {
      this.$root.$emit('updateTooltip', e)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/scoreboard/bo-scoreboard-actions.scss';
</style>
