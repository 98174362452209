/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M48.014 42.889l-9.553-4.776A2.63 2.63 0 0137 35.748v-3.381c.229-.28.47-.599.719-.951a22.886 22.886 0 002.954-5.799A3.968 3.968 0 0043 22v-4c0-.963-.36-1.896-1-2.625v-5.319c.056-.55.276-3.824-2.092-6.525C37.854 1.188 34.521 0 30 0s-7.854 1.188-9.908 3.53C17.724 6.231 17.944 9.506 18 10.056v5.319c-.64.729-1 1.662-1 2.625v4c0 1.217.553 2.352 1.497 3.109.916 3.627 2.833 6.36 3.503 7.237v3.309c0 .968-.528 1.856-1.377 2.32l-8.921 4.866A9.018 9.018 0 007 50.762V54c0 4.746 15.045 6 23 6s23-1.254 23-6v-3.043a8.973 8.973 0 00-4.986-8.068z" _fill="#90a4ae"/>'
  }
})
