const optimizeVideos = (url) => {
  const optimizationClass = 'is-video-optimized'

  const videos = document.querySelectorAll(`iframe[src^="${url}"]`)
  videos.forEach((video) => {
    const parent = video.parentNode
    if (!parent.classList.contains(optimizationClass)) {
      parent.classList.add(optimizationClass)
    }
  })
}

export { optimizeVideos }
