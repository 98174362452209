import { Plugin, Node } from 'tiptap'

export default class CustomImage extends Node {
  commands (_ref) {
    let type = _ref.type
    return function (attrs) {
      return function (state, dispatch) {
        let selection = state.selection
        let position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
        let node = type.create(attrs)
        let transaction = state.tr.insert(position, node)
        dispatch(transaction)
      }
    }
  }

  get name () {
    return 'image'
  }

  get schema () {
    return {
      inline: true,
      attrs: {
        src: {},
        alt: {
          default: null
        },
        title: {
          default: null
        }
      },
      group: 'inline',
      draggable: true,
      parseDOM: [
        {
          tag: 'img[src]',
          getAttrs: function getAttrs (dom) {
            return {
              src: dom.getAttribute('src'),
              title: dom.getAttribute('title'),
              alt: dom.getAttribute('alt')
            }
          }
        }
      ],
      toDOM: function toDOM (node) {
        return ['img', node.attrs]
      }
    }
  }

  get plugins () {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            drop (view, event) {
              // TODO: Upload to server and put image into editor content (improvment)
            }
          }
        }
      })
    ]
  }
}
