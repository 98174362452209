import { socket } from '@/plugins/Socket'
import Cookies from 'js-cookie'
import router from '@/router'
import liveblogAPI from '@/api/LiveblogAPI'
import websockets from '@/constants/websockets'
import urls from '@/helpers/constants/urls'

const checkStatus = (context, status) => {
  if (status === 401) {
    // 401 not connected
    logout(context) // Log out the user
  } else if (status === 403) {
    router.push({ path: '/403' })
  }
  return false // User still logged in
}

const loadUser = async ({ commit, dispatch }) => {
  try {
    const data = await liveblogAPI.fetchUser()
    if (!data) { throw Error('Unauthorized') }

    commit('SET_USER', data.user)
    commit('SET_CUSTOMIZATION', data.customization)
  } catch (error) {
    return error.response && dispatch('checkStatus', error.response.status)
  }
}

const logout = (context) => {
  Cookies.remove('token', { domain: urls.cookieDomain })
  context.commit('RESET_TOKEN')
  const uri = `?redirect=${encodeURIComponent(window.location.href)}`
  window.location = `${urls.foAccount}${uri}` // Redirect to login page with current page in the query string
}

const updateCustomization = ({ state }) => {
  socket.io.emit(websockets.UPDATE_CUSTOMIZATION, {
    customerId: state.user && state.user.customer,
    customization: state.customization,
    settings: state.settings
  })
}

export default {
  checkStatus,
  loadUser,
  logout,
  updateCustomization
}
