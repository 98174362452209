<template>
  <div class="liveblog-creator">
    <transition name="fade">
      <div v-if="!isForm">
        <!-- Header -->
        <div class="liveblog-creator__header">
          <h2>{{ $t('app.live.create.topics.title') }}</h2>
          <ida-button
            class="ida-button-finalize-topic"
            round
            type="primary"
            width="180"
            height="40"
            @click="handleClick"
          >
            {{ $t('app.live.create.topics.cta') }}
          </ida-button>
        </div>

        <!-- Topics -->
        <div class="liveblog-creator__topics">
          <Loader v-if="!topics" />
          <TopicCard
            v-for="topic in topics"
            v-else
            :key="topic.id"
            :topic="topic"
          />
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="isForm" class="liveblog-creator__form">
        <LiveForm />
      </div>
    </transition>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import LiveForm from '@/components/Creator/LiveForm'
import TopicCard from '@/components/Creator/TopicCard'
import topicsJSON from '@/locales/topics.json'

export default {
  layout: 'dashboard',
  components: { Loader, LiveForm, TopicCard },
  data () {
    return {
      topics: topicsJSON
    }
  },
  computed: {
    isForm () {
      return this.$route.name === 'newLiveblogForm'
    }
  },
  async mounted () {
    this.$store.commit('scoreboard/RESET')
    this.$store.dispatch('lives/resetTopics')
  },
  methods: {
    handleClick () {
      this.$router.push({ name: 'newLiveblogForm' })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/creator.scss';
</style>
