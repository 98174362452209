<template>
  <div
    v-if="scoreboardActions.length > 0"
    class="scoreboard-actions__resume-container"
  >
    <div class="scoreboard__resume-actions">
      <ScoreboardBOResumeAction
        v-for="(action, key) in scoreboardActions"
        :key="key"
        :action="action"
        :team="scoreboardTeam1"
      />
    </div>
    <div class="teams_divider" />
    <div class="scoreboard__resume-actions">
      <ScoreboardBOResumeAction
        v-for="(action, key) in scoreboardActions"
        :key="key"
        :action="action"
        class="reverse"
        :team="scoreboardTeam2"
      />
    </div>
  </div>
  <div
    v-else
    class="scoreboard-actions__resume-container--empty"
  >
    <div class="scoreboard-actions__resume--empty">
      <div class="scoreboard-actions__text">
        {{ $t('app.scoreboard.bo.action.empty') }}
      </div>
      <ida-button
        class="scoreboard-actions--empty_resume"
        type="primary"
        @click="activeTab = 'actions'"
      >
        {{ $t('app.scoreboard.bo.btn.add') }}
      </ida-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ScoreboardBOResumeAction from './ScoreboardBOResumeAction'

export default {
  name: 'ScoreboardBOResumeActions',
  components: {
    ScoreboardBOResumeAction
  },
  computed: {
    ...mapGetters({
      scoreboardActions: 'scoreboard/scoreboardActions',
      scoreboardTeam1: 'scoreboard/scoreboardTeam1',
      scoreboardTeam2: 'scoreboard/scoreboardTeam2'
    }),
    activeTab: {
      get () { return this.$store.getters['scoreboard/activeTab'] },
      set (value) { this.$store.commit('scoreboard/SET_ACTIVE_TAB', value) }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/scoreboard/bo-scoreboard-actions.scss';
</style>
