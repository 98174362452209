const RESET_URL = (state) => { state.url = '' }
const SET_ERROR = (state, value) => { state.modalError = value }
const SET_TYPE = (state, value) => { state.type = value }
const SET_VIDEO_OPTIMIZATION_ENABLED = (state, value) => { state.videoOptimizationEnabled = value }
const SET_URL = (state, value) => { state.url = value }
const TOGGLE_OPEN_MODAL = (state, value) => { state.isModalOpen = value }

export default {
  RESET_URL,
  SET_ERROR,
  SET_TYPE,
  SET_VIDEO_OPTIMIZATION_ENABLED,
  SET_URL,
  TOGGLE_OPEN_MODAL
}
