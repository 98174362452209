<template>
  <div :class="timelineClasses">
    <h3 class="liveblog-timeline__title" @click="toggleTimeline">
      <span>{{ $t('app.display.timeline.title') }}</span>
      <i class="ida-icon-arrow-light" />
    </h3>

    <div class="liveblog-timeline__content">
      <ul v-if="highlightedPosts && highlightedPosts.length">
        <li
          v-for="(post, key) in highlightedPosts"
          :key="key"
          class="liveblog-timeline__item"
        >
          <a class="liveblog-timeline__link" :href="`#${formatId(post.id)}`">
            <span class="liveblog-timeline__date">{{ formatDate(post.createdAt).date }}</span>
            {{ post.title || '(...)' }}
          </a>
        </li>
      </ul>
      <small v-else>{{ $t('app.display.timeline.empty') }}</small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDate } from '@/helpers/utils/date'
import { formatId } from '@/helpers/utils/others'
import { getOffset } from '@/helpers/utils/window'

export default {
  name: 'Timeline',
  data () {
    return {
      isTimelineOpen: false
    }
  },
  computed: {
    ...mapGetters({
      highlightedPosts: 'posts/highlightedPosts'
    }),
    timelineClasses () {
      return [
        'liveblog-timeline',
        { 'liveblog-timeline--full': this.isTimelineOpen }
      ]
    }
  },
  methods: {
    formatDate,
    formatId,
    toggleTimeline () {
      this.isTimelineOpen = !this.isTimelineOpen

      if (this.isTimelineOpen) {
        const timeline = document.querySelector('.liveblog-timeline')
        const timelineOffsetTop = getOffset(timeline).top
        window.scrollTo({ top: timelineOffsetTop, behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/timeline.scss';
</style>
