<template>
  <div class="dashboard-header-container">
    <div class="dashboard-header-left">
      <Breadcrumb />

      <h1 class="dashboard-header__display-title">
        {{ $t('app.live.form.update') }}
      </h1>
    </div>

    <div class="dashboard-header-right">
      <ida-button
        type="primary"
        width="140"
        height="35"
        @click="updateLive"
      >
        {{ $t('app.actions.save') }}
      </ida-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Breadcrumb from './Breadcrumb'

export default {
  name: 'Header',
  components: {
    Breadcrumb
  },
  computed: {
    ...mapGetters({
      currentLive: 'lives/currentLive',
      liveOnLoad: 'lives/liveOnLoad',
      timelineEnabled: 'lives/currentTimelineEnabled',
      highlightedPostsHaveAtLeastOneEmptyTitle: 'posts/highlightedPostsHaveAtLeastOneEmptyTitle'
    }),
    currentHasChanges: {
      get () { return this.$store.getters['lives/currentHasChanges'] },
      set (live) { this.$store.commit('lives/SET_CURRENT_HAS_CHANGES', live) }
    }
  },
  methods: {
    updateLive () {
      // Refresh changes detection
      this.currentHasChanges = false
      window.onbeforeunload = null

      if (this.timelineEnabled && this.highlightedPostsHaveAtLeastOneEmptyTitle) {
        if (!confirm(this.$t('app.liveSettings.timeline.emptyHighlightedPostsTitles'))) { return }
      }

      this.$store.dispatch('lives/updateLive')
      setTimeout(() => this.$router.push({ name: 'editLiveblog', params: { id: this.$route.params.id } }), 70)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/editor/editor-header.scss';

.settings .dashboard {
  align-items: flex-end;
}
</style>
