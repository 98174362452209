<template>
  <div class="preview-container">
    <div class="preview-header">
      <div class="preview-header__items">
        <div
          class="preview-header__item preview-header__item--live"
          :class="{isActive: activeTab === PreviewTabs.LIVE}"
          @click="toggleTab(PreviewTabs.LIVE)"
        >
          <span ref="live" class="preview-header__item-title">
            {{ $t('app.preview.tabs.live') }}
          </span>
        </div>

        <div
          class="preview-header__item preview-header__item--pinned"
          :class="{isActive: activeTab === PreviewTabs.PINNED}"
          @click="toggleTab(PreviewTabs.PINNED)"
        >
          <span ref="pinned" class="preview-header__item-title">
            {{ $t('app.preview.tabs.pinned') }}
          </span>
          <span
            v-if="hasHighlighted"
            class="small-bubble small-bubble--highlighted"
            :title="highlightedPosts && highlightedPosts.length"
          >{{ highlightedPosts.length > 99 ? '99' : highlightedPosts.length }}</span>
          <span v-if="hasPinned" class="small-bubble small-bubble--pinned">1</span>
        </div>

        <div
          v-if="commentsEnabled"
          class="preview-header__item preview-header__item--comments"
          :class="{isActive: activeTab === PreviewTabs.COMMENTS}"
          @click="toggleTab(PreviewTabs.COMMENTS)"
        >
          <span ref="comments" class="preview-header__item-title">
            {{ $t('app.preview.tabs.comments') }}
          </span>
          <span
            v-if="hasComments"
            class="small-bubble small-bubble--comments"
            :title="comments && comments.length"
          >{{ comments.length > 99 ? '99' : comments.length }}</span>
        </div>
      </div>

      <div class="preview-header__divider">
        <span ref="previewDivider" class="line" />
      </div>
    </div>

    <!-- Live content -->
    <div v-if="activeTab === PreviewTabs.LIVE" class="posts-container">
      <Loader v-if="!posts" />
      <div v-else-if="hasPosts" class="posts-container__classic">
        <Post
          v-for="(post, key) in posts"
          :key="key"
          :post="post"
        />
      </div>
      <div v-else class="posts-container__error">
        <span>{{ $t('app.preview.live.empty') }}</span>
      </div>
    </div>

    <!-- Pinned content (+ highlighted) -->
    <div v-else-if="activeTab === PreviewTabs.PINNED" class="posts-container">
      <div v-if="hasPinned || hasHighlighted">
        <div v-if="pinnedPost" class="posts-container__pinned">
          <Post :post="pinnedPost" />
        </div>

        <div v-if="highlightedPostsOnly" class="posts-container__pinned">
          <Post
            v-for="(post, key) in highlightedPostsOnly"
            :key="key"
            :post="post"
          />
        </div>
      </div>
      <div v-else class="posts-container__error">
        <span>{{ $t('app.preview.pinned.empty') }}</span>
      </div>
    </div>

    <!-- Comments content -->
    <div
      v-if="activeTab === PreviewTabs.COMMENTS && commentsEnabled"
      class="posts-container posts-container--comments"
    >
      <!-- Comments reload topbar -->
      <div
        v-if="activeTab === PreviewTabs.COMMENTS && hasNewComments && commentsEnabled"
        class="comments__reload"
        @click="loadNewComments"
      >
        <div class="comments__reload__left">
          <svgicon name="refresh" original />
          <span>
            <b>{{ countNewComments }}</b> {{ countNewComments > 1 ? $t('app.editor.comments.newcomments') : $t('app.editor.comments.newcomment') }}
          </span>
        </div>
        <div class="comments__reload__right">
          <a>{{ $t('app.editor.comments.reload') }}</a>
        </div>
      </div>

      <div v-if="hasComments" class="posts-container__classic">
        <Comment
          v-for="(comment, key) in filteredComments"
          :key="key"
          :comment="comment"
        />
      </div>
      <div v-else class="posts-container__error">
        <span>{{ $t('app.preview.comments.empty') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/common/Loader'
import { PreviewTabs } from '@/constants/editor'
import websockets from '@/constants/websockets'
import Post from './Post'
import Comment from './Comment'

export default {
  name: 'EditorPreview',
  components: {
    Loader,
    Post,
    Comment
  },
  data () {
    return {
      PreviewTabs,
      activeTab: PreviewTabs.LIVE
    }
  },
  computed: {
    ...mapGetters({
      posts: 'posts/posts',
      hasPosts: 'posts/hasPosts',
      pinnedPost: 'posts/pinnedPost',
      hasPinned: 'posts/hasPinned',
      hasHighlighted: 'posts/hasHighlighted',
      highlightedPosts: 'posts/highlightedPosts',
      highlightedPostsOnly: 'posts/highlightedPostsOnly',
      countNewComments: 'comments/countNewComments',
      hasNewComments: 'comments/hasNewComments',
      comments: 'comments/comments',
      hasComments: 'comments/hasComments',
      commentsEnabled: 'lives/currentCommentsEnabled'
    }),
    filteredComments: {
      get () {
        // Hide new comments until the journalist wants to reload them
        const comments = this.comments
        if (comments && this.hasNewComments && this.activeTab === PreviewTabs.COMMENTS) {
          return comments.slice(this.countNewComments, comments.length)
        }
        return comments
      }
    },
    isCommentsTabActive: {
      get () { return this.$store.getters['comments/isTabActive'] },
      set (boolean) { this.$store.commit('comments/SET_IS_TAB_ACTIVE', boolean) }
    }
  },
  mounted () {
    this.isCommentsTabActive = false // Fix: on component mount, reset comments' tab state
    this.calcDividerWidth(this.activeTab)
    window.addEventListener('resize', this.calcDividerWidth(this.activeTab))
  },
  updated () {
    this.calcDividerWidth(this.activeTab)
  },
  methods: {
    calcDividerWidth (tab) {
      const parentClass = '.preview-header'
      if (this.$refs[tab]) {
        const line = this.$refs[tab].getBoundingClientRect()
        const parent = document.querySelector(parentClass).getBoundingClientRect()
        this.$refs.previewDivider.style.width = `${line.width}px`
        this.$refs.previewDivider.style.left = `calc(${line.left}px - ${parent.left}px)`
      }
    },
    loadNewComments () {
      this.$store.dispatch('comments/resetNewComments')
    },
    toggleTab (tab) {
      this.activeTab = tab
      this.calcDividerWidth(tab)

      this.isCommentsTabActive = tab === PreviewTabs.COMMENTS
      this.$nextTick(() => { this.$store.dispatch('lives/reloadEmbeds') })
    },
    async updatePreview (posts) {
      let postIdArray = []
      let livePostIdArray = []

      for (const post of posts) { postIdArray.push(post.id) }
      for (const post of this.posts) { livePostIdArray.push(post.id) }

      let difference = postIdArray.filter(x => !livePostIdArray.includes(x))
      // need to be change in case there's multiple change
      this.$socket().emit(websockets.GET_ONE_POST, difference[0])
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/preview.scss';
@import '@/assets/stylesheets/components/comments.scss';
</style>
