<template>
  <div class="inputMultiple">
    <div class="inputMultiple-field">
      <ida-input
        ref="input"
        v-model="value"
        :placeholder="placeholder"
        class="is-customized"
        @input.native="handleInput"
        @keyup.enter.native="handleSubmit"
      />
    </div>

    <div class="inputMultiple-tags">
      <transition-group name="fadeInLeft" appear tag="div">
        <tag
          v-for="(tag, index) in tags"
          :key="`${index}${value}`"
          removable
          @remove="remove"
        >
          {{ tag }}
        </tag>
      </transition-group>
    </div>
  </div>
</template>

<script>
import Tag from './Tag'

export default {
  name: 'InputMultiple',
  components: { Tag },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: null
    },
    validationPattern: {
      type: RegExp,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      value: null
    }
  },
  computed: {
    showAddButton () {
      return !this.hasError
    }
  },
  methods: {
    handleInput (value) {
      const text = value.srcElement._value

      // force submit if comma or space keys are pressed
      const lastCharacter = text.slice(-1)
      const validateCharacters = [',', ' ']
      if (validateCharacters.includes(lastCharacter)) {
        this.value = this.value.substring(0, this.value.length - 1)
        this.handleSubmit()
      }
    },
    handleSubmit () {
      const isEmpty = !this.value || this.value === ''
      if (isEmpty) { return }

      const isValueMatchingPattern = !this.validationPattern || this.validationPattern.test(this.value)
      if (!isValueMatchingPattern) { return this.$noty.error(this.errorMessage) }

      const tag = this.value.toLowerCase().trim()
      if (this.tags.indexOf(tag) !== -1) { return }

      this.tags.push(tag)
      this.value = ''
    },
    remove (tag) {
      const index = this.tags.indexOf(tag)
      if (index !== -1) {
        this.tags.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inputMultiple {
  width: 300px;

  &-field {
    position: relative;

    &-error {
      position: absolute;
      right: 16px;
      color: var(--c-red);
      font-size: 12px;
      line-height: 15px;
      top: 50%;
      margin-top: -8px;
    }

    &-button {
      position: absolute;
      top: 17%;
      right: 8px;
    }
  }

  &-tags {
    margin-top: 4px;

    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .tag {
        margin-left: 0;
      }
    }
  }
}
</style>
