<template>
  <div
    class="bo-scoreboard-container"
    :class="{'is-open': isScoreboardOpen}"
  >
    <div class="bo-scoreboard-top">
      <div class="bo-scoreboard--team bo-scoreboard__team_1-container">
        <img
          v-if="scoreboard.team_1.imageUrl"
          :src="scoreboard.team_1.imageUrl"
        >
        <span>{{ scoreboard.team_1.value }}</span>
      </div>
      <div class="score-container">
        <div class="input-group">
          <div
            v-tippy="{ html: '#tooltip', placement: 'top' }"
            :data-tip="$t('app.scoreboard.increment')"
            @mouseover="updateTooltip"
            @click="() => scoreboardScoreTeam1 += 1"
          >
            <svgicon name="arrow" class="input-increment" />
          </div>
          <input
            v-model.number="scoreboardScoreTeam1"
            class="score"
            type="number"
            pattern="[0-9]*"
            min="0"
            max="999"
          >
          <div
            v-tippy="{ html: '#tooltip' }"
            :data-tip="$t('app.scoreboard.decrement')"
            :class="{'disabled' : scoreboardScoreTeam1 - 1 < 0}"
            @mouseover="updateTooltip"
            @click="() => scoreboardScoreTeam1 - 1 >= 0 ? scoreboardScoreTeam1 -= 1 : null"
          >
            <svgicon name="arrow" class="input-decrement" />
          </div>
        </div>
        <input
          v-model="scoreboardTime"
          v-tippy="{ html: '#tooltip' }"
          wrap="true"
          :placeholder="$t('app.scoreboard.status')"
          :data-tip="$t('app.scoreboard.update_status')"
          class="time"
          type="text"
          @mouseover="updateTooltip"
        >
        <div class="input-group">
          <div
            v-tippy="{ html: '#tooltip', placement: 'top' }"
            :data-tip="$t('app.scoreboard.increment')"
            @mouseover="updateTooltip"
            @click="() => scoreboardScoreTeam2 += 1"
          >
            <svgicon name="arrow" class="input-increment" />
          </div>
          <input
            v-model.number="scoreboardScoreTeam2"
            class="score"
            type="number"
            pattern="[0-9]*"
            min="0"
            max="999"
          >
          <div
            v-tippy="{ html: '#tooltip' }"
            :data-tip="$t('app.scoreboard.decrement')"
            :class="{'disabled' : scoreboardScoreTeam2 - 1 < 0}"
            @mouseover="updateTooltip"
            @click="() => scoreboardScoreTeam2 - 1 >= 0 ? scoreboardScoreTeam2 -= 1 : null"
          >
            <svgicon name="arrow" class="input-decrement" />
          </div>
        </div>
      </div>
      <div class="bo-scoreboard--team bo-scoreboard__team_2-container">
        <span>{{ scoreboard.team_2.value }}</span>
        <img
          v-if="scoreboard.team_2.imageUrl"
          :src="scoreboard.team_2.imageUrl"
        >
      </div>
    </div>
    <div class="divider" />
    <div
      class="bo-scoreboard-bottom"
      @click.self="handleScoreboardClick"
    >
      <div>
        <span
          class="bo-scoreboard-bottom--span"
          :class="{'is-active' : activeTab === 'actions'}"
          @click="openTab('actions')"
        >
          {{ $t('app.scoreboard.add.action') }}
        </span>
        <span
          class="bo-scoreboard-bottom--span"
          :class="{'is-active' : activeTab === 'resume'}"

          @click="openTab('resume')"
        >{{ $t('app.scoreboard.resume') }}</span>
      </div>
      <div
        class="arrow"
        :class="{'is-active': isScoreboardOpen }"
        @click.self="handleScoreboardClick"
      >
        <svgicon name="arrow" />
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isScoreboardOpen && activeTab === 'actions'"
        class="bo-scoreboard__actions-container"
      >
        <div class="divider" />
        <ScoreboardBOActions />
      </div>
      <div
        v-else-if="isScoreboardOpen && activeTab === 'resume'"
        class="bo-scoreboard__actions-container"
      >
        <div class="divider" />
        <ScoreboardBOResumeActions />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import websockets from '@/constants/websockets'

import ScoreboardBOActions from './ScoreboardBOActions'
import ScoreboardBOResumeActions from './ScoreboardBOResumeActions'

export default {
  name: 'ScoreboardBO',
  components: {
    ScoreboardBOActions,
    ScoreboardBOResumeActions
  },
  data () {
    return {
      timer: null,
      isAction: false,
      isResume: true
    }
  },
  computed: {
    ...mapGetters({
      scoreboardScoreTeam1: 'scoreboard/scoreboardScoreTeam1',
      scoreboardScoreTeam2: 'scoreboard/scoreboardScoreTeam2',
      willSave: 'scoreboard/willSave',
      isScoreboardOpen: 'scoreboard/isScoreboardOpen'
    }),
    activeTab: {
      get () { return this.$store.getters['scoreboard/activeTab'] },
      set (value) { this.$store.commit('scoreboard/SET_ACTIVE_TAB', value) }
    },
    scoreboard: {
      get () { return this.$store.getters['scoreboard/scoreboard'] },
      set (value) { this.$store.commit('scoreboard/SET_SCOREBOARD', value) }
    },
    scoreboardScoreTeam1: {
      get () { return this.$store.getters['scoreboard/scoreboardScoreTeam1'] },
      set (value) {
        this.$store.commit('scoreboard/SET_SCORE_TEAM_1', value)
        this.updateScoreboard()
      }
    },
    scoreboardScoreTeam2: {
      get () { return this.$store.getters['scoreboard/scoreboardScoreTeam2'] },
      set (value) {
        this.$store.commit('scoreboard/SET_SCORE_TEAM_2', value)
        this.updateScoreboard()
      }
    },
    scoreboardTime: {
      get () { return this.$store.getters['scoreboard/scoreboardTime'] },
      set (value) {
        this.$store.commit('scoreboard/SET_TIME', value)
        this.updateScoreboard()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.calcWidth)
      this.calcWidth()
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calcWidth)
  },
  methods: {
    calcWidth () {
      setTimeout(() => {
        const element = document.querySelector('.bo-scoreboard-container')
        const editor = document.querySelector('.editor').getBoundingClientRect()

        element.style.width = `${editor.width}px`
      }, 1)
    },
    handleScoreboardClick () {
      if (this.$store.getters['scoreboard/isScoreboardOpen']) {
        this.$store.dispatch('scoreboard/resetScoreboardActionsState')
      }
      this.$store.commit('scoreboard/TOGGLE_IS_SCOREBOARD_OPEN')
    },
    openTab (tab) {
      if (!this.isScoreboardOpen) { this.$store.commit('scoreboard/TOGGLE_IS_SCOREBOARD_OPEN') }
      this.activeTab = tab
    },
    setSaveTimer () {
      const timer = 3 // In seconds
      this.timer = setTimeout(() => {
        this.$store.commit('scoreboard/SET_WILL_SAVE', false)
        if (!this.willSave) {
          const scoreboardId = this.$store.getters['lives/currentScoreboardId']
          if (scoreboardId) {
            this.$store.dispatch('scoreboard/saveScoreboard')
          } else {
            this.$store.dispatch('scoreboard/createScoreboard')
            this.$socket().on(websockets.GET_ONE_SCOREBOARD, result => {
              this.$store.commit('lives/SET_CURRENT_SCOREBOARD_ID', result.id)
              this.$store.dispatch('lives/updateLive')
            })
          }
        }
      }, timer * 1000)
    },
    updateScoreboard () {
      clearTimeout(this.timer)
      this.setSaveTimer()
    },
    updateTooltip (e) {
      this.$root.$emit('updateTooltip', e)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/scoreboard/bo-scoreboard.scss';
</style>
