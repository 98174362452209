<template>
  <div class="dashboard-header-container">
    <div class="dashboard-header-left">
      <Breadcrumb />
      <ScoreboardBO v-if="scoreboardEnabled" />
    </div>

    <div class="dashboard-header-right">
      <div class="dashboard-header-right__buttons">
        <div
          v-tippy="{ html: '#tooltip' }"
          class="js-button-export"
          :data-tip="$t('app.editorheader.buttons.export')"
          @click="openExport"
          @mouseover="updateTooltip"
          @keyup.tab="updateTooltip"
        >
          <i class="ida-icon-export" />
        </div>
        <div
          v-tippy="{ html: '#tooltip' }"
          class="js-button-settings"
          :data-tip="$t('app.pages.settings')"
          @click="redirectToParameters"
          @mouseover="updateTooltip"
          @keyup.tab="updateTooltip"
        >
          <svgicon name="settings" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '../Breadcrumb'
import ScoreboardBO from '../Scoreboard/ScoreboardBO/ScoreboardBO'

export default {
  name: 'EditorHeader',
  components: {
    Breadcrumb,
    ScoreboardBO
  },
  computed: {
    ...mapGetters({
      scoreboardEnabled: 'lives/currentScoreboardEnabled',
      currentLive: 'lives/currentLive',
      posts: 'posts/posts'
    }),
    isExportModalOpen: {
      get () { return this.$store.getters['export/isModalOpen'] },
      set (boolean) { this.$store.commit('export/SET_IS_MODAL_OPEN', boolean) }
    }
  },
  methods: {
    openExport () {
      this.isExportModalOpen = true
    },
    redirectToParameters () {
      this.$router.push({ name: 'liveblogSettings', params: { id: this.$route.params.id } })
    },
    updateTooltip (e) {
      this.$root.$emit('updateTooltip', e)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/editor/editor-header.scss';
</style>
