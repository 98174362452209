/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.707.293A1 1 0 00.293 1.707L6.586 8 .293 14.293a1 1 0 101.414 1.414L8 9.414l6.293 6.293a1 1 0 001.414-1.414L9.414 8l6.293-6.293A1 1 0 0014.293.293L8 6.586 1.707.293z" _fill="#000"/>'
  }
})
