<template>
  <div>
    <div
      class="preview-post"
      :class="{ 'pinned': post.pinned, 'highlighted': post.highlighted }"
      :data-id="post.id"
    >
      <div class="preview-post__header">
        <div class="preview-post__header__actions-container">
          <i
            v-if="post.surveyId"
            v-tippy="{ html: '#tooltip' }"
            class="ida-icon-search"
            :data-tip="$t('app.preview.stats')"
            @click="openSurveyStats"
            @mouseover="updateTooltip"
            @keyup.tab="updateTooltip"
          />
          <i
            v-tippy="{ html: '#tooltip' }"
            :data-tip="$t('app.preview.anchor.copy')"
            class="ida-icon-link"
            @click="copyAnchorLink(post.id)"
            @mouseover="updateTooltip"
            @keyup.tab="updateTooltip"
          />
          <i
            v-tippy="{ html: '#tooltip' }"
            :data-tip="post.highlighted ? $t('app.preview.highlighted.remove') : $t('app.preview.highlighted.add')"
            :class="post.highlighted ? 'ida-icon-star-rounded-filled highlighted' : 'ida-icon-star-rounded'"
            @click="toggleHighlightPost(post.id, post.highlighted, post.title)"
            @mouseover="updateTooltip"
            @keyup.tab="updateTooltip"
          />
          <i
            v-tippy="{ html: '#tooltip' }"
            :data-tip="post.pinned ? $t('app.preview.pinned.remove') : $t('app.preview.pinned.add')"
            :class="post.pinned ? 'ida-icon-pin-filled pinned' : 'ida-icon-pin'"
            @click="togglePinPost(post.id)"
            @mouseover="updateTooltip"
            @keyup.tab="updateTooltip"
          />
          <i
            v-tippy="{ html: '#tooltip' }"
            :data-tip="$t('app.preview.update')"
            class="ida-icon-edit"
            @click="openEditMode"
            @mouseover="updateTooltip"
            @keyup.tab="updateTooltip"
          />
          <ida-popper trigger="click">
            <template slot-scope="{ doClose }">
              <p>{{ $t('app.actions.delete.question') }}</p>
              <div>
                <ida-button type="primary" round @click="() => { doClose(); deletePost(post.id); }">
                  {{ $t('app.actions.delete.confirm') }}
                </ida-button>
              </div>
              <div>
                <ida-button type="text" @click="doClose">
                  {{ $t('app.actions.delete.cancel') }}
                </ida-button>
              </div>
            </template>
            <i
              slot="reference"
              v-tippy="{ html: '#tooltip' }"
              class="ida-icon-bin-2"
              :data-tip="$t('app.preview.delete')"
              @mouseover="updateTooltip"
              @keyup.tab="updateTooltip"
            />
          </ida-popper>
        </div>

        <div class="preview-post__header__tags-container">
          <div class="preview-post__header__tag preview-post__header__tag--date">
            <span class="preview-post__header__publish-date" :title="formatDate(post.createdAt).title">
              {{ formatDate(post.createdAt).date }} {{ updated }}
            </span>
          </div>

          <span v-if="post.action" class="preview-post__header__tag preview-post__header__tag--action">
            <div v-if="picto" class="picto" v-html="picto" />
            <i v-else class="ida-icon-liveblog" />
          </span>

          <span v-if="post.highlighted" class="preview-post__header__tag preview-post__header__tag--highlighted">
            {{ $t('app.preview.highlighted.tag') }}
          </span>
          <span v-if="post.pinned" class="preview-post__header__tag preview-post__header__tag--pinned">
            {{ $t('app.preview.pinned.tag') }}
          </span>
          <span v-if="post.surveyId" class="preview-post__header__tag preview-post__header__tag--survey">
            {{ $t('app.editor.menubar.survey') }}
          </span>
        </div>
      </div>

      <div v-if="post.surveyCode" class="iDalgo-survey" :data-key="post.surveyCode" />
      <div v-else>
        <h2 class="preview-post__title">
          {{ post.title }}
        </h2>
        <div class="preview-post__content" v-html="post.content">
          {{ post.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDate } from '@/helpers/utils/date'
import { formatId } from '@/helpers/utils/others'
import websockets from '@/constants/websockets'

export default {
  name: 'Post',
  props: {
    post: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      timelineEnabled: 'lives/currentTimelineEnabled'
    }),
    picto () {
      if (this.post.action) {
        const topics = this.$store.getters['dashboardActions/actions']
        if (!topics || !topics.length) { return null }

        let item = null
        topics.find(topic => {
          item = topic.actions.find(action => action.id === parseInt(this.post.action))
          return item
        })
        return item && item.picto
      }
      return null
    },
    player () {
      if (this.post.dataPlayer) {
        const { value } = JSON.parse(this.post.dataPlayer)
        return value
      }
      return null
    },
    updated () {
      if (this.post.createdAt !== this.post.updatedAt) { return ` (${this.$t('app.preview.edited')})` }
      return null
    }
  },
  methods: {
    formatDate,
    deletePost (id) {
      this.$store.dispatch('posts/deletePost', id)
    },
    openSurveyStats () {
      setTimeout(() => { this.$store.dispatch('survey/openStatsModal', this.post.surveyId) }, 10)
    },
    openEditMode () {
      // Handle survey post case
      if (this.post.surveyId) {
        return setTimeout(() => this.$store.dispatch('survey/openUpdateModal', this.post.surveyId), 10)
      }

      this.$root.$emit('editPost', this.post)
    },
    toggleHighlightPost (id, currentStatus, title) {
      // if post's title is empty, make sure the user is aware that it may be a problem for the timeline
      if (
        this.timelineEnabled &&
        currentStatus === false && title.trim() === ''
      ) {
        if (!confirm(this.$t('app.preview.highlighted.emptyTitle'))) { return }
      }

      this.$socket().emit(websockets.TOGGLE_HIGHLIGHT_ONE_POST, id)
    },
    togglePinPost (id) {
      this.$socket().emit(websockets.TOGGLE_PIN_ONE_POST, id)
    },
    async copyAnchorLink (id) {
      const link = `#${formatId(id)}`
      await this.$clipboard(link)
    },
    updateTooltip (e) {
      this.$root.$emit('updateTooltip', e)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/preview-post.scss';
@import '@/assets/stylesheets/components/embed-video.scss';
</style>
