/* eslint-disable */
require('./arrow')
require('./big-link')
require('./close')
require('./loader')
require('./people-active')
require('./people')
require('./question')
require('./refresh')
require('./settings')
require('./user')
require('./x')
