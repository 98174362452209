import Vue from 'vue'
import Router from 'vue-router'
import iDalgoDefault from '@/layouts/iDalgo-Default'
import iDalgoViewer from '@/layouts/iDalgo-Viewer'
import iDalgoLiveblogs from '@/views/iDalgo-Liveblogs'
import iDalgoLiveblog from '@/views/iDalgo-Liveblog'
import iDalgoLiveblogViewer from '@/views/iDalgo-Liveblog-Viewer'
import iDalgoLiveblogCreator from '@/views/iDalgo-Liveblog-Creator'
import iDalgoLiveblogSettings from '@/views/iDalgo-Liveblog-Settings'
import iDalgoCustomerSettings from '@/views/iDalgo-Customer-Settings'
import iDalgo403 from '@/views/iDalgo-403'

Vue.use(Router)

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */ '../views/About')

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/app',
      name: 'app',
      meta: { requiresAuth: true, layout: 'default' },
      redirect: { name: 'archives' },
      component: iDalgoDefault,
      children: [
        {
          path: 'archives',
          name: 'archives',
          component: iDalgoLiveblogs
        },
        {
          path: 'liveblogs/new',
          name: 'newLiveblog',
          component: iDalgoLiveblogCreator
        },
        {
          path: 'liveblogs/new/form',
          name: 'newLiveblogForm',
          component: iDalgoLiveblogCreator
        },
        {
          path: 'settings',
          name: 'customerSettings',
          component: iDalgoCustomerSettings
        }
      ]
    },
    {
      path: '/app',
      name: 'editor',
      meta: { requiresAuth: true, layout: 'default' },
      props: { breadcrumb: true, topbar: false },
      component: iDalgoDefault,
      children: [
        {
          path: 'liveblogs/:id',
          name: 'editLiveblog',
          component: iDalgoLiveblog
        },
        {
          path: 'liveblogs/:id/settings',
          name: 'liveblogSettings',
          component: iDalgoLiveblogSettings,
          meta: { bodyClass: 'idalgo-components-customized' }
        }
      ]
    },
    {
      path: '/live',
      name: 'live',
      component: iDalgoViewer,
      children: [
        {
          path: ':id',
          name: 'viewLiveblog',
          component: iDalgoLiveblogViewer
        },
        {
          path: ':id/amp',
          name: 'viewLiveblogAmp',
          props: { isAmp: true },
          component: iDalgoLiveblogViewer
        }
      ]
    },
    {
      path: '/live-ssr',
      name: 'liveSSR',
      component: iDalgoViewer,
      children: [
        {
          path: ':id',
          name: 'viewLiveblogSSR',
          component: iDalgoLiveblogViewer
        },
        {
          path: ':id/amp',
          name: 'viewLiveblogAmpSSR',
          props: { isAmp: true },
          component: iDalgoLiveblogViewer
        }
      ]
    },
    { path: '/', meta: { requiresAuth: true }, redirect: { name: 'archives' } },
    { path: '*', meta: { requiresAuth: true }, redirect: { name: 'archives' } },
    {
      path: '/403',
      name: 'forbidden',
      component: iDalgo403
    }
  ]
})

export default router
