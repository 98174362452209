const RESET_TOKEN = (state) => { state.user.token = '' }
const SET_CUSTOMIZATION = (state, value) => { state.customization = value }
const SET_CUSTOM_COLOR_PRIMARY = (state, value) => { state.customization.colors.primary = value }
const SET_CUSTOM_COLOR_TEXT = (state, value) => { state.customization.colors.text = value }
const SET_CUSTOM_COLOR_SECONDARY = (state, value) => { state.customization.colors.secondary = value }
const SET_CUSTOM_COLOR_BORDERS = (state, value) => { state.customization.colors.borders = value }
const SET_CUSTOM_COLOR_TIMELINE = (state, value) => { state.customization.colors.timeline = value }
const SET_CUSTOM_COLOR_BACKGROUND = (state, value) => { state.customization.colors.background = value }
const SET_CUSTOM_VIDEO_OPTIMIZER_URLS_PREFIXES = (state, value) => { state.customization.settings.videoOptimizerURLsPrefixes = value }
const SET_USER = (state, user) => { state.user = Object.assign(user) }

export default {
  RESET_TOKEN,
  SET_CUSTOMIZATION,
  SET_CUSTOM_COLOR_PRIMARY,
  SET_CUSTOM_COLOR_TEXT,
  SET_CUSTOM_COLOR_SECONDARY,
  SET_CUSTOM_COLOR_BORDERS,
  SET_CUSTOM_COLOR_TIMELINE,
  SET_CUSTOM_COLOR_BACKGROUND,
  SET_CUSTOM_VIDEO_OPTIMIZER_URLS_PREFIXES,
  SET_USER
}
