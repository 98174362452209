import { scripts } from '../constants/embeds'
import urls from '@/helpers/constants/urls'

const formatId = id => {
  // uuidv4 ID without hyphens (used for posts' ID, who need to be a valid CSS #id selector for AMP)
  return id.replace(/-/g, '')
}

const getExportCodes = liveId => {
  const url = urls.cdn
  return {
    javascript: `<div class="iDalgo-liveblog" data-key="${liveId}"></div><script src="${url}/bundles/iframe/v2.js" async></script>`,
    iframe: `<iframe src="${url}/live/${liveId}" scrolling="auto" frameborder="0" style="min-height: 800px; width: 100%;"></iframe>`,
    ssr: `${url}/live-ssr/${liveId}`,
    amp: `${url}/live-ssr/${liveId}/amp`,
    ampiframe: `<amp-iframe width="400" height="800" sandbox="allow-scripts allow-same-origin" layout="responsive" resizable frameborder="0" src="${url}/live/${liveId}"><div overflow tabindex=0 role=button aria-label="">...</div><amp-img placeholder src="https://via.placeholder.com/1x1.png?text=&nbsp;" layout="fill"></amp-img></amp-iframe>`
  }
}

const getFirstLetter = string => {
  return string && string.charAt(0)
}

const getInitials = name => {
  const letters = name && name.split(' ').map(word => word.slice(0, 1))
  if (!letters || !letters.length) { return false }
  if (letters.length === 1) { return `${letters[0]}` }
  return `${letters[0]}${letters[letters.length - 1]}`.toUpperCase()
}

const getRandomizedUsername = () => {
  return `User-${Math.round(Math.random() * 100000)}`
}

const includeScript = name => {
  if (!name) { return }

  const script = scripts.find(item => item.name === name)
  if (!script) { return }
  if (document.body.querySelector(`script[src="${script.src}"]`)) { return } // If already in the DOM, do not include it again

  const el = document.createElement('script')
  el.setAttribute('src', script.src)
  if (script.async) { el.setAttribute('async', script.async) }
  if (script.defer) { el.setAttribute('defer', script.defer) }
  document.body.appendChild(el)
  return script
}

const parseHooks = text => {
  return replaceAll(replaceAll(text, '&lt;', '<'), '&gt;', '>')
}

const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace)
}

const replaceSvgTitle = (svg, title) => {
  return svg ? svg.replace(/<title>[\s\S]*?<\/title>/g, `<title>${title || ''}</title>`) : null
}

export { formatId, getExportCodes, getFirstLetter, getInitials, getRandomizedUsername, includeScript, parseHooks, replaceAll, replaceSvgTitle }
