import EmbedTwitter from '@/helpers/embeds/EmbedTwitter'
import EmbedInstagram from '@/helpers/embeds/EmbedInstagram'

const reloadSocials = async () => {
  const twitter = new EmbedTwitter()
  twitter.reload()

  const instagram = new EmbedInstagram()
  instagram.reload()
}

export default {
  reloadSocials
}
