import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import Cookies from 'js-cookie'
import urls from '@/helpers/constants/urls'

const state = {
  user: {
    token: Cookies.get('token', { domain: urls.cookieDomain }),
    id: null,
    username: null,
    customer: null,
    firstName: null,
    lastName: null
  },
  customization: {
    colors: {
      primary: '#43a5f5',
      text: '#1f2332',
      secondary: '#b3b3b3',
      borders: 'rgba(210, 210, 210, 0.4)',
      timeline: '#f1f1f1',
      background: '#fcfcfc'
    },
    settings: {
      videoOptimizerURLsPrefixes: []
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
