<template>
  <div class="dashboard-container customization">
    <aside>
      <section class="customization-section">
        <h3>1. {{ this.$t('app.settings.sections.colors') }}</h3>
        <div class="colors">
          <div class="colors-group">
            <label>{{ this.$t('app.settings.inputs.colorPrimary.label') }}</label>
            <el-color-picker
              v-model="customColorPrimary"
              show-alpha
              @active-change="color => customColorPrimary = color"
            />
            <small>{{ this.$t('app.settings.inputs.colorPrimary.description') }}</small>
          </div>

          <div class="colors-group">
            <label>{{ this.$t('app.settings.inputs.colorText.label') }}</label>
            <el-color-picker
              v-model="customColorText"
              show-alpha
              @active-change="color => customColorText = color"
            />
            <small>{{ this.$t('app.settings.inputs.colorText.description') }}</small>
          </div>

          <div class="colors-group">
            <label>{{ this.$t('app.settings.inputs.colorSecondary.label') }}</label>
            <el-color-picker
              v-model="customColorSecondary"
              show-alpha
              @active-change="color => customColorSecondary = color"
            />
            <small>{{ this.$t('app.settings.inputs.colorSecondary.description') }}</small>
          </div>

          <div class="colors-group">
            <label>{{ this.$t('app.settings.inputs.colorBorders.label') }}</label>
            <el-color-picker
              v-model="customColorBorders"
              show-alpha
              @active-change="color => customColorBorders = color"
            />
            <small>{{ this.$t('app.settings.inputs.colorBorders.description') }}</small>
          </div>

          <div class="colors-group">
            <label>{{ this.$t('app.settings.inputs.colorTimeline.label') }}</label>
            <el-color-picker
              v-model="customColorTimeline"
              @active-change="color => customColorTimeline = color"
            />
            <small>{{ this.$t('app.settings.inputs.colorTimeline.description') }}</small>
          </div>

          <div class="colors-group">
            <label>{{ this.$t('app.settings.inputs.colorBackground.label') }}</label>
            <el-color-picker
              v-model="customColorBackground"
              @active-change="color => customColorBackground = color"
            />
            <small>{{ this.$t('app.settings.inputs.colorBackground.description') }}</small>
          </div>
        </div>

        <div class="buttons">
          <ida-button type="primary" @click="save">
            {{ $t('app.actions.save') }}
          </ida-button>
        </div>
      </section>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'CustomerSettings',
  computed: {
    customColorPrimary: {
      get () { return this.$store.getters['user/customColorPrimary'] },
      set (value) { this.$store.commit('user/SET_CUSTOM_COLOR_PRIMARY', value) }
    },
    customColorText: {
      get () { return this.$store.getters['user/customColorText'] },
      set (value) { this.$store.commit('user/SET_CUSTOM_COLOR_TEXT', value) }
    },
    customColorSecondary: {
      get () { return this.$store.getters['user/customColorSecondary'] },
      set (value) { this.$store.commit('user/SET_CUSTOM_COLOR_SECONDARY', value) }
    },
    customColorBorders: {
      get () { return this.$store.getters['user/customColorBorders'] },
      set (value) { this.$store.commit('user/SET_CUSTOM_COLOR_BORDERS', value) }
    },
    customColorTimeline: {
      get () { return this.$store.getters['user/customColorTimeline'] },
      set (value) { this.$store.commit('user/SET_CUSTOM_COLOR_TIMELINE', value) }
    },
    customColorBackground: {
      get () { return this.$store.getters['user/customColorBackground'] },
      set (value) { this.$store.commit('user/SET_CUSTOM_COLOR_BACKGROUND', value) }
    }
  },
  methods: {
    save () {
      this.$store.dispatch('user/updateCustomization')
    }
  }
}
</script>

<style lang="scss" scoped>
.customization {
  display: flex;
  margin-top: 3vh;

  aside {
    flex: 1;
    max-width: 48%;
  }

  &-section {
    background: white;
    padding: 10px 33px;

    @media (max-width: 800px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    h3 {
      text-transform: uppercase;
      font-weight: bold;
      color: #424242;
      margin-bottom: 0;
    }

    .colors {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
      grid-auto-rows: minmax(100px, auto);
      margin: 20px 0;
      overflow: auto;

      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }

      &-group {
        display: inline-flex;
        flex-direction: column;
        max-width: 170px;

        > *:not(:first-child) {
          margin-top: 7px;
        }

        label {
          text-transform: uppercase;
          font-weight: bold;
          color: #424242;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
