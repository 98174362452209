<template>
  <ida-page-403
    :locale="locale"
    product="LIVEBLOG"
  />
</template>

<script>
// import i18n from '@/lang'

export default {
  name: 'IDalgoLiveblog403',
  computed: {
    locale () {
      // return i18n.locale
      return 'fr'
    }
  }
}
</script>
