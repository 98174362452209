import Vue from 'vue'
import Vuex from 'vuex'
import commentsModule from './modules/comments'
import dashboardActionsModule from './modules/dashboardActions'
import editorModule from './modules/editor'
import exportModule from './modules/export'
import livesModule from './modules/lives'
import mediasModule from './modules/medias'
import postsModule from './modules/posts'
import scoreboardModule from './modules/scoreboard'
import surveyModule from './modules/survey'
import uploadImageModule from './modules/uploadImage'
import userModule from './modules/user'

Vue.use(Vuex)

const app = {
  state: {
    url: {
      from: ''
    }
  },
  getters: {
    previousPage: state => state.url.from
  },
  mutations: {
    FROMURL_UPDATED (state, from) {
      state.url.from = from
    }
  }
}

export default new Vuex.Store({
  state: app.state,
  getters: app.getters,
  mutations: app.mutations,
  modules: {
    comments: commentsModule,
    dashboardActions: dashboardActionsModule,
    editor: editorModule,
    export: exportModule,
    lives: livesModule,
    medias: mediasModule,
    posts: postsModule,
    scoreboard: scoreboardModule,
    survey: surveyModule,
    uploadImage: uploadImageModule,
    user: userModule
  }
})
