<template>
  <div id="idalgo-app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
#idalgo-app {
  // v-calendar
  .vc {
    &-border {
      border-color: rgba(66, 66, 66, 0.1);
    }

    &-text-base {
      font-size: 14px;
      padding: 12px 10px;
    }

    &-popover-content-wrapper {
      z-index: 1001;
    }
  }
}

.ida-dropdown,
.ida-dropdown-menu {
  z-index: 1000 !important;
}
</style>
