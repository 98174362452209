import { Link } from 'tiptap-extensions'
import { isValidAnchor } from '@/helpers/utils/url'

export default class CustomLink extends Link {
  get schema () {
    return {
      attrs: {
        href: {
          default: null
        },
        target: {
          default: null
        }
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs: dom => ({
            href: dom.getAttribute('href'),
            target: dom.getAttribute('target')
          })
        }
      ],
      toDOM: node => {
        const target = isValidAnchor(node.attrs.href) ? '_self' : '_blank'
        return ['a', {
          ...node.attrs,
          target,
          rel: 'noopener noreferrer nofollow'
        }, 0]
      }
    }
  }
}
