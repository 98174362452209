<template>
  <div class="scoreboard">
    <div class="scoreboard-help" v-text="helpText" />

    <div class="scoreboard-container">
      <ida-guided-search
        ref="team1"
        class="team_1"
        :selected-choice="team1"
        :data-sources="dataSources"
        :placeholder="$t('app.scoreboard.team1')"
        @inputChange="handleChangeTeam1"
      />
      <div class="scoreboard-scores">
        <input
          v-model="team1Score"
          type="number"
          pattern="[0-9]*"
          min="0"
        >
        <input
          v-model="team2Score"
          type="number"
          pattern="[0-9]*"
          min="0"
        >
      </div>
      <ida-guided-search
        ref="team2"
        class="ida-guided-search-right team_2"
        :placeholder="$t('app.scoreboard.team2')"
        :selected-choice="team2"
        :data-sources="dataSources"
        @inputChange="handleChangeTeam2"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { WebsocketAPI, WebsocketResponse } from '../../api/WebsocketAPI'

export default {
  name: 'Scoreboard',
  computed: {
    ...mapGetters({
      formattedTopic: 'lives/formattedTopic'
    }),
    dataSources () {
      return [
        {
          category: this.$t('app.editor.answer.category.team'),
          request: this.searchTeamByNameRequest,
          limit: 3
        }
      ]
    },
    searchTeamByNameRequest () {
      switch (this.formattedTopic?.wsSport) {
        case (WebsocketAPI.SPORT_FOOTBALL):
          return window.wsAPI.searchFootballTeamByName
        case (WebsocketAPI.SPORT_RUGBY):
          return window.wsAPI.searchRugbyTeamByName
        case (WebsocketAPI.SPORT_MULTISPORTS):
          return window.wsAPI.searchMultisportsTeamByName
        default:
          return () => new WebsocketResponse([])
      }
    },
    helpText () {
      switch (this.formattedTopic?.wsSport) {
        case (WebsocketAPI.SPORT_FOOTBALL):
          return `⚽ ${this.$t('app.scoreboard.help.football')}`
        case (WebsocketAPI.SPORT_RUGBY):
          return `🏉 ${this.$t('app.scoreboard.help.rugby')}`
        case (WebsocketAPI.SPORT_MULTISPORTS):
          return `🤾🏀🏐 ${this.$t('app.scoreboard.help.multisports')}`
        default:
          return null
      }
    },
    team1: {
      get () { return this.$store.getters['scoreboard/scoreboardTeam1'] },
      set (value) {
        this.$store.commit('scoreboard/SET_TEAM_1', value)
      }
    },
    team2: {
      get () { return this.$store.getters['scoreboard/scoreboardTeam2'] },
      set (value) {
        this.$store.commit('scoreboard/SET_TEAM_2', value)
      }
    },
    team1Score: {
      get () { return this.$store.getters['scoreboard/scoreboardScoreTeam1'] },
      set (value) {
        this.$store.commit('scoreboard/SET_SCORE_TEAM_1', value)
      }
    },
    team2Score: {
      get () { return this.$store.getters['scoreboard/scoreboardScoreTeam2'] },
      set (value) {
        this.$store.commit('scoreboard/SET_SCORE_TEAM_2', value)
      }
    }
  },
  methods: {
    handleChangeTeam1 (data) {
      this.$store.commit('scoreboard/SET_TEAM_1', data)
    },
    handleChangeTeam2 (data) {
      this.$store.commit('scoreboard/SET_TEAM_2', data)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/scoreboard/scoreboard.scss';
</style>
