import { EditorModes } from '@/constants/editor'

const mode = state => state.mode
const isSaving = state => state.isSaving
const isPostEditing = state => state.mode === EditorModes.POST_EDITING
const isCommentAnswering = state => state.mode === EditorModes.COMMENT_ANSWERING

export default {
  mode,
  isSaving,
  isPostEditing,
  isCommentAnswering
}
