/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people-active': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<defs><circle pid="0" id="svgicon_people-active_a" cx="10" cy="10" r="10"/></defs><g _fill="none" fill-rule="evenodd"><circle pid="1" cx="10" cy="10" r="10" opacity=".095" _fill="#42A5F5"/><mask id="svgicon_people-active_b" _fill="#fff"><use xlink:href="#svgicon_people-active_a"/></mask><g _fill="#42A5F5" fill-rule="nonzero" mask="url(#svgicon_people-active_b)"><path pid="2" d="M1.889 21c.058-.676.321-3.293.497-3.582 1.638-2.62 4.94-3.798 8.003-3.798 3.06 0 6.365 1.175 8 3.796.174.28.442 2.914.5 3.584h-17zm.879-.774H18.01c-.035-.319-.286-2.375-.311-2.415-1.485-2.384-4.54-3.417-7.31-3.417-2.77 0-5.824 1.033-7.31 3.417-.044.082-.274 2.068-.311 2.415zM10.389 5c3.81 0 5.738 4.464 3.04 7.059a4.366 4.366 0 01-3.04 1.212c-2.372 0-4.298-1.855-4.298-4.136C6.091 6.85 8.015 5 10.39 5zm0 .774c-1.93 0-3.495 1.504-3.495 3.36 0 1.86 1.563 3.363 3.495 3.363.964 0 1.839-.377 2.471-.985 2.194-2.11.628-5.738-2.471-5.738z"/></g></g>'
  }
})
