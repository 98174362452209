import Penpal from 'penpal'

class SurveyConnector {
  constructor ({ url, container, getSurvey }) {
    this.connection = Penpal.connectToChild({
      appendTo: container,
      url,
      methods: {
        resize: this.resize.bind(this),
        getSurvey
      }
    })
  }

  resize (height) {
    if (this.connection.iframe) { this.connection.iframe.style.height = `${height}px` }
  }

  destroy () {
    if (this.connection) { this.connection.destroy() }
  }
}

export default SurveyConnector
