import Penpal from 'penpal'
import ResizeObserver from 'resize-observer-polyfill'
import { getOffset } from '../utils/window'

// iframe connector for iframe exports (resize observer on display page)
class IframeConnector {
  constructor () {
    this.connection = null
    this.parent = null
    this.ro = null
    this.timeline = undefined
    this.timelineHasChanges = false
  }

  async connect () {
    await new Promise((resolve, reject) => {
      this.connection = Penpal.connectToParent({
        methods: {
          documentHeight: () => document.body.offsetHeight,
          handleTimelinePosition: (offsets) => this.handleTimelinePosition(offsets)
        }
      })

      this.connection.promise.then(parent => {
        this.parent = parent
        this.ro = new ResizeObserver(this.resize.bind(this))
        this.ro.observe(document.querySelector('.display'))
        resolve()

        this.loadAnchorsListeners()
      })
    })
  }

  handleTimelinePosition ({ currentScrollOffsetTop, parentIframeOffsetTop }) {
    const postsOffsetTop = getOffset(document.querySelector('.liveblog-content')).top
    const timelineHeight = this.timeline.offsetHeight
    const iframeHeight = document.body.offsetHeight

    // `parentIframeOffsetTop` is the position of the loaded iframe from top of document (parent).
    const timelineOffsetTop = parentIframeOffsetTop + postsOffsetTop + 30
    const iframeBottomMinusTimelineOffsetTop = parentIframeOffsetTop + iframeHeight - timelineHeight

    if (currentScrollOffsetTop < timelineOffsetTop) {
      this.resetTimelinePosition()
    } else if (
      currentScrollOffsetTop >= timelineOffsetTop &&
      currentScrollOffsetTop < iframeBottomMinusTimelineOffsetTop
    ) {
      const fixedOnTopOffset = currentScrollOffsetTop - parentIframeOffsetTop
      this.setTimelinePosition(fixedOnTopOffset)
    }
  }

  // when using JS integration method, iFrame's calculated height makes classic anchors not working anymore.
  // that's why we add some listeners on detected anchors to handle scrolling.
  loadAnchorsListeners () {
    const anchors = document.querySelectorAll('.liveblog-viewer a[href^="#"]')
    anchors.forEach(anchor => {
      const event = () => this.scrollToAnchor(anchor)
      anchor.removeEventListener('click', event)
      anchor.addEventListener('click', event)
    })
  }

  startTimelineHandling () {
    if (!this.parent) { return }
    this.timeline = document.querySelector('.liveblog-timeline')
    this.parent.startTimelineHandling()
  }

  stopTimelineHandling () {
    if (!this.parent) { return }
    this.parent.stopTimelineHandling()
  }

  scrollToAnchor (anchor) {
    if (!this.parent) { return }
    const anchorId = anchor.href.split('#')[1]
    const target = document.getElementById(anchorId)
    // note: `targetOffset` will be the target's offset relative to the iframe
    // in `v2.js` integration manager (parent side), we add, to this value, the iframe offset relative to the document
    const targetOffsetTop = getOffset(target).top
    this.parent.scrollTo(targetOffsetTop)
  }

  setTimelinePosition (offsetTop) {
    this.timeline.style.position = 'fixed'
    this.timeline.style.top = `${offsetTop - 30}px`
    this.timelineHasChanges = true
  }

  resetTimelinePosition () {
    if (this.timelineHasChanges) {
      this.timeline.style.position = 'absolute'
      const timelineDefaultOffset = getOffset(document.querySelector('.liveblog-content')).top
      this.timeline.style.top = `${timelineDefaultOffset}px`
      this.timelineHasChanges = false
    }
  }

  resize (entries) {
    if (!this.parent) { return }
    for (const entry of entries) {
      let { height } = entry.contentRect
      this.parent.resize(height)
    }
  }

  destroy () {
    if (this.connection) { this.connection.destroy() }
  }
}

export default new IframeConnector()
