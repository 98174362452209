<template>
  <div v-if="commentsEnabled">
    <div v-if="commentIsCreated" class="comments-container">
      <div class="comments-container__success">
        {{ $t('app.display.comments.success') }}<br>
        <a
          @click="showCommentForm"
        >{{ $t('app.display.comments.resend') }}</a>
      </div>
    </div>
    <div v-else class="comments-container">
      <label for="comment">{{ $t('app.display.comments.title') }}</label>
      <div class="comments__textarea">
        <ida-input
          id="comment"
          v-model="content"
          type="textarea"
          required
          :placeholder="$t('app.display.comments.placeholder')"
        />
        <svgicon name="question" class="comments__input-icon" original />
      </div>

      <div class="comments-container__footer">
        <div class="comments__input">
          <ida-input
            id="author"
            v-model="author"
            :placeholder="$t('app.display.comments.author.placeholder')"
            maxlength="20"
          />
          <svgicon name="people-active" class="comments__input-icon" original />
        </div>
        <ida-button
          class="ida-button-send-message"
          type="primary"
          @click="sendComment"
        >
          {{ $t('app.display.comments.submit') }}
        </ida-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CommentForm',
  computed: {
    ...mapGetters({
      commentIsCreated: 'comments/isCreated',
      commentsEnabled: 'lives/currentCommentsEnabled'
    }),
    author: {
      get () { return this.$store.getters['comments/commentAuthor'] },
      set (author) { this.$store.commit('comments/SET_AUTHOR', author) }
    },
    content: {
      get () { return this.$store.getters['comments/commentContent'] },
      set (content) { this.$store.commit('comments/SET_CONTENT', content) }
    }
  },
  methods: {
    async sendComment () {
      if (this.content === '') {
        return document.querySelector('textarea#comment').reportValidity() // Fix: Textarea (really) required
      }
      await this.$store.dispatch('comments/createComment', this.$route.params.id)
      this.$store.dispatch('comments/resetComment')
    },
    showCommentForm () {
      this.$store.commit('comments/SET_IS_CREATED', false)
    }
  }
}
</script>
