<template>
  <div class="upload-image">
    <div v-if="isUploadImageModalOpen">
      <div class="ida-msgbox-content">
        <div class="filepond-container">
          <FilePond
            :accepted-file-types="['image/png', 'image/jpeg', 'image/svg+xml']"
            :label-idle="$t('app.editor.filepond.label')"
            max-file-size="4MB"
            :server="{
              process: {
                url: uploadApiUrl,
                onload: onResponse,
                headers: configAuthenticated.headers
              }
            }"
          />
          {{ $t('app.editor.filepond.or') }}
          <ida-input
            id="image_url"
            v-model="imageUrl"
            type="url"
            pattern="https?:\/\/(www\.)?.{2,256}"
            required
            :placeholder="$t('app.editor.filepond.input')"
            @click.native="e => e.target.focus()"
            @keyup.enter.native="submit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import 'filepond/dist/filepond.min.css'
import urls from '@/helpers/constants/urls'
import Http from '@/helpers/Http'

export default {
  name: 'UploadImage',
  props: {
    callback: {
      type: Function,
      default: null
    }
  },
  computed: {
    configAuthenticated () {
      return Http.headers
    },
    isUploadImageModalOpen: {
      get () { return this.$store.getters['uploadImage/isModalOpen'] },
      set (boolean) { this.$store.commit('uploadImage/SET_IS_MODAL_OPEN', boolean) }
    },
    imageUrl: {
      get () { return this.$store.getters['uploadImage/imgUrl'] },
      set (value) { this.$store.commit('uploadImage/SET_IS_IMGURL', value) }
    },
    FilePond: vueFilePond(
      FilePondPluginImageResize,
      FilePondPluginFileValidateType,
      FilePondPluginFileValidateSize
    ),
    uploadApiUrl () {
      return `${urls.api}/upload`
    }
  },
  mounted () {
    // Handle closing popup on Esc key press
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.close()
      }
    })
  },
  methods: {
    async submit () {
      // Validate type URL
      const inputUrl = document.querySelector('input#image_url')
      if (!inputUrl.validity.valid) { return inputUrl.reportValidity() }

      await this.callback({ src: this.imageUrl })
      await this.close()
      this.imageUrl = ''
    },
    close () {
      this.imageUrl = ''
      document.removeEventListener('keydown', this.keydownEvent)
      this.isUploadImageModalOpen = false
    },
    onResponse (response) {
      const data = JSON.parse(response)
      if (data && data.path) { this.callback({ src: data.path }) }
      this.isUploadImageModalOpen = false // Close upload image dialog in store
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/upload-image.scss';
</style>

<style lang="scss">
@import '@/assets/stylesheets/variables.scss';

/* Override styles (not scoped) */
.upload-image {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  width: 60%;
  background: white;
  margin-top: 10px;
  border-radius: 4px;

  .filepond-container {
    text-align: center;
    color: #999;

    .filepond--wrapper {
      margin-bottom: 7px;
    }

    .filepond--root {
      margin-bottom: 0;
    }

    .filepond--panel-root {
      background: #fff;
      border: 2px dashed #eee;
      border-radius: 4px;
    }

    .filepond--drop-label {
      color: #555;
      font-weight: 700;
      cursor: pointer;

      label {
        cursor: pointer;
      }
    }
  }

  .ida-input .ida-input__inner {
    background: #fff;
    border: 1px solid #ededed;
    font-size: 14px;
    padding: 5px 8px;
    margin-top: 7px;
  }

  .animated-active {
    .ida-input > input {
      border-color: $--color-primary !important;
    }
  }
}
</style>
