/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'x': {
    width: 300,
    height: 271,
    viewBox: '0 0 300 271',
    data: '<path pid="0" d="M236 0h46L181 115l118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123L-1.1 0h94.9l65.5 86.6zm-16.1 244h25.5L80.4 26H53z"/>'
  }
})
