<template>
  <div id="editor" :class="liveblogEditorClasses" @click="onClick">
    <div v-if="showFog" class="embed__background" @click="closeModal" />
    <EditorHeader />
    <Editor />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import liveblogAPI from '@/api/LiveblogAPI'
import Editor from '@/components/Editor/Editor'
import EditorHeader from '@/components/Editor/EditorHeader'
import websockets from '@/constants/websockets'
import { includeScript } from '@/helpers/utils/others'

export default {
  components: { Editor, EditorHeader },
  data () {
    return {
      liveId: this.$route.params.id,
      title: null,
      listenersAreBinded: false
    }
  },
  computed: {
    ...mapGetters({
      posts: 'posts/posts',
      comments: 'comments/comments',
      isEmbedModalOpen: 'medias/isModalOpen',
      isScoreboardOpen: 'scoreboard/isScoreboardOpen',
      customer: 'user/customer',
      customVideoOptimizerURLsPrefixes: 'user/customVideoOptimizerURLsPrefixes'
    }),
    liveblogEditorClasses () {
      return [
        'dashboard',
        'liveblog-editor',
        { 'dashboard--hasActions': this.actions && this.actions.length }
      ]
    },
    showFog () {
      return this.isEmbedModalOpen || this.isUploadImageModalOpen || this.isScoreboardOpen
    },
    actions: {
      get () { return this.$store.getters['dashboardActions/actions'] },
      set (actions) { this.$store.commit('dashboardActions/SET_ACTIONS', actions) }
    },
    currentLive: {
      get () { return this.$store.getters['lives/currentLive'] },
      set (live) { this.$store.commit('lives/SET_CURRENT_LIVE', live) }
    },
    isSurveyModalOpen: {
      get () { return this.$store.getters['survey/isModalOpen'] },
      set (boolean) { this.$store.commit('survey/SET_IS_MODAL_OPEN', boolean) }
    },
    isUploadImageModalOpen: {
      get () { return this.$store.getters['uploadImage/isModalOpen'] },
      set (boolean) { this.$store.commit('uploadImage/SET_IS_MODAL_OPEN', boolean) }
    },
    scoreboard: {
      get () { return this.$store.getters['scoreboard/scoreboard'] },
      set (value) { this.$store.commit('scoreboard/SET_SCOREBOARD', value) }
    },
    scoreboardActions: {
      get () { return this.$store.getters['scoreboard/scoreboardActions'] },
      set (value) { this.$store.commit('scoreboard/SET_SCOREBOARD_ACTIONS', value) }
    }
  },
  created () {
    includeScript('twitter')
  },
  async mounted () {
    try {
      const { live } = await liveblogAPI.fetchLive(this.liveId)
      if (this.customer !== live.customer) { throw Error('Unauthorized') }
      this.$store.dispatch('lives/setCurrentLive', { live })
    } catch (e) {
      return this.$router.push({ path: '/' })
    }

    await this.$store.dispatch('posts/fetchPosts', {
      liveId: this.liveId,
      paginationOffset: 0
    })
    this.reloadEmbeds()
    this.optimizeVideos()

    /**
     * Websockets
     */
    this.$socket().emit('join', this.liveId)
    this.$socket().on('joined', () => {
      if (this.listenersAreBinded) { this.unbindListeners() }
      this.bindListeners()
      this.listenersAreBinded = true

      this.$socket().emit(websockets.GET_COMMENTS, { isPersonal: true })
    })

    this.$socket().on('reconnect', () => {
      this.$socket().emit('leave', this.liveId)
      this.$socket().emit('join', this.liveId)
    })
  },
  beforeDestroy () {
    this.$socket().emit('leave', this.liveId)
    this.$socket().removeAllListeners()
    this.$store.commit('posts/RESET_POSTS')
    this.$store.commit('lives/SET_CURRENT_LIVE', {})
  },
  methods: {
    bindListeners () {
      /**
       * Posts
       */
      this.$socket().on(websockets.GET_ONE_POST, post => {
        this.$store.dispatch('posts/addPost', post)
        this.reloadEmbeds()

        // clear editor when post successfully created
        this.$root.$emit('clear')
        this.$store.commit('editor/SET_IS_SAVING', false)
      })
      this.$socket().on(websockets.DELETE_ONE_POST, post => {
        this.$store.commit('posts/DELETE_POST', post)
        this.reloadEmbeds()
      })
      this.$socket().on(websockets.UPDATE_ONE_POST, post => {
        this.$store.commit('posts/UPDATE_POST', post)
        this.reloadEmbeds()

        // clear editor when post successfully updated
        this.$root.$emit('clear')
        this.$store.commit('editor/SET_IS_SAVING', false)
      })
      this.$socket().on(websockets.TOGGLE_PIN_ONE_POST, post => {
        this.$store.dispatch('posts/togglePin', post)
      })
      this.$socket().on(websockets.TOGGLE_HIGHLIGHT_ONE_POST, post => {
        this.$store.dispatch('posts/toggleHighlight', post)
      })

      /**
       * Comments
       */
      this.$socket().on(websockets.GET_COMMENTS, comments => {
        this.$store.commit('comments/SET_COMMENTS', comments)
        // TODO: clear
      })
      this.$socket().on(websockets.CREATE_ONE_COMMENT, comment => {
        this.$store.dispatch('comments/addNewComment', comment)
      })
      this.$socket().on(websockets.DELETE_ONE_COMMENT, comment => {
        this.$store.commit('comments/DELETE_COMMENT', comment)
      })

      /**
       * Live
       */
      this.$socket().on(websockets.UPDATE_ONE_LIVE, live => {
        this.$store.commit('lives/SET_CURRENT_LIVE', live)
      })

      /**
       * Scoreboard
       */
      if (this.currentLive.scoreboardEnabled) {
        this.$socket().on(websockets.GET_ONE_SCOREBOARD, result => {
          if (result) { this.scoreboard = result.data }
          this.$socket().emit(websockets.GET_SCOREBOARD_ACTIONS, {
            scoreboardId: this.currentLive.scoreboardId,
            isPersonal: true
          })
        })
        this.$socket().on(websockets.UPDATE_ONE_SCOREBOARD, result => {
          if (result) { this.scoreboard = result.data }
        })
        this.$socket().on(websockets.GET_SCOREBOARD_ACTIONS, (result) => {
          if (result) { this.scoreboardActions = result }
        })
        this.$socket().on(websockets.CREATE_ONE_SCOREBOARD_ACTION, () => {
          this.$socket().emit(websockets.GET_SCOREBOARD_ACTIONS, { scoreboardId: this.currentLive.scoreboardId })
        })
        this.$socket().on(websockets.DELETE_ONE_SCOREBOARD_ACTION, () => {
          this.$socket().emit(websockets.GET_SCOREBOARD_ACTIONS, { scoreboardId: this.currentLive.scoreboardId })
        })

        this.$socket().emit(websockets.GET_ONE_SCOREBOARD, { scoreboardId: this.currentLive.scoreboardId })
      }
    },
    unbindListeners () {
      // unbind listeners except `joined`
      this.$socket().off(websockets.GET_ONE_SCOREBOARD)
      this.$socket().off(websockets.UPDATE_ONE_SCOREBOARD)
      this.$socket().off(websockets.GET_SCOREBOARD_ACTIONS)
      this.$socket().off(websockets.CREATE_ONE_SCOREBOARD_ACTION)
      this.$socket().off(websockets.DELETE_ONE_SCOREBOARD_ACTION)
      this.$socket().off(websockets.GET_ONE_POST)
      this.$socket().off(websockets.DELETE_ONE_POST)
      this.$socket().off(websockets.UPDATE_ONE_POST)
      this.$socket().off(websockets.TOGGLE_PIN_ONE_POST)
      this.$socket().off(websockets.TOGGLE_HIGHLIGHT_ONE_POST)
      this.$socket().off(websockets.GET_COMMENTS)
      this.$socket().off(websockets.CREATE_ONE_COMMENT)
      this.$socket().off(websockets.DELETE_ONE_COMMENT)
      this.$socket().off(websockets.UPDATE_ONE_LIVE)
    },
    onClick (e) {
      // Handle closing modals from outside components
      if (
        (this.isUploadImageModalOpen || this.isSurveyModalOpen) &&
        !e.target.closest('.upload-image') &&
        !e.target.closest('.ida-msgbox-content')
      ) {
        this.isUploadImageModalOpen = false
        this.isSurveyModalOpen = false
      }
    },
    closeModal () {
      if (this.isScoreboardOpen) {
        this.$store.dispatch('scoreboard/resetScoreboardActionsState')
        this.$store.commit('scoreboard/TOGGLE_IS_SCOREBOARD_OPEN')
      }
      this.$store.commit('medias/TOGGLE_OPEN_MODAL', false)
      this.$store.commit('medias/RESET_URL')
      this.$store.commit('medias/SET_ERROR', false)
    },
    reloadEmbeds () {
      this.$nextTick(() => {
        this.$store.dispatch('lives/reloadEmbeds')
      })
    },
    optimizeVideos () {
      this.customVideoOptimizerURLsPrefixes.forEach((pattern) => {
        window.VideoOptimizer(pattern)
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/variables.scss';

.liveblog-editor {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__content {
    min-height: 0;
    margin-bottom: 20px;
    flex: 1;
  }
}

// Tippy popper (shortcut commands)
.tippy-popper {
  .title__shortcut {
    margin-left: 3px;
    font-size: 80%;
    font-weight: bold;
    color: #999;
  }
}

.editor .editor-input-group-inside {
  padding: 15px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $--color-border;

  & > div {
    display: inline-block;
  }
}

.ida-dropdown-item {
  display: flex;
  flex-direction: row;

  .ida-guided-search-item-text-category {
    padding-left: 10px;
  }

  .picto svg {
    max-height: 22px;
    max-width: 22px;
    margin-right: 20px;
    transform: translateY(2px);
  }
}
</style>
