/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#42A5F5" fill-rule="evenodd"><path pid="0" d="M1.08 10c0-4.949 4-8.977 8.949-8.948a8.94 8.94 0 017.94 13.062l-.114.202.029.201.863 4.23-4.259-.863-.2-.03-.202.116A8.76 8.76 0 0110 18.977c-4.949 0-8.948-4.028-8.92-8.977z" _stroke="#42A5F5" fill-opacity=".197"/><path pid="1" d="M10.201 12.158H9.77c-.115 0-.173.086-.173.173v.431c0 .115.087.173.173.173h.431c.115 0 .173-.087.173-.173v-.431c.029-.087-.058-.173-.173-.173zM11.352 7.497c-.345-.317-.805-.46-1.352-.46s-.978.143-1.295.431c-.287.259-.46.604-.546 1.065 0 .057 0 .115.028.143.03.03.087.058.115.087l.374.057a.216.216 0 00.202-.144c.057-.316.201-.575.374-.719.201-.201.46-.288.777-.288.316 0 .575.116.805.317.23.201.317.46.317.748a.812.812 0 01-.115.432c-.087.143-.23.316-.49.518-.258.23-.43.374-.517.489-.115.143-.23.316-.288.46-.086.201-.115.46-.115.777 0 .115.086.172.173.172h.316a.186.186 0 00.173-.172c0-.202.028-.345.057-.432a.972.972 0 01.173-.345c.057-.086.23-.259.46-.46.346-.317.576-.576.69-.777.116-.202.173-.46.173-.72.03-.489-.143-.863-.489-1.18z"/></g>'
  }
})
