<template>
  <div ref="tweet" class="tweet-container" :data-src="src" />
</template>

<script>
export default {
  name: 'Twitter',
  props: ['node'],
  computed: {
    src () {
      return this.node && this.node.attrs.src
    }
  },
  mounted () {
    this.loadTweets()
  },
  methods: {
    loadTweets () {
      if (!window.twttr || !window.twttr.widgets) { return }
      window.twttr.widgets.load()
      let code = this.src
      let id

      if (this.src.includes('blockquote')) {
        const codeHref = new RegExp(/[";]+(https:\/\/twitter\.com\/([\s\S][^/]*?)\/status\/([\s\S]*?))[?"&]/)
        code = codeHref.exec(this.src)[1]
      }

      const lastPart = code.split('/').pop()
      id = lastPart.split('?').shift()

      setTimeout(() => {
        window.twttr.widgets.createTweet(
          id,
          document.querySelector('.tweet-container'),
          {
            theme: 'light'
          }
        )
      }, 100)
    }
  }
}
</script>
