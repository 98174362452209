<template>
  <div class="fb-container" v-html="src" />
</template>

<script>
export default {
  name: 'Facebook',
  props: ['node'],
  computed: {
    src () {
      return this.node && this.node.attrs.src
    }
  }
}
</script>
