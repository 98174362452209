<template>
  <div class="export">
    <div
      v-if="isExportModalOpen"
      class="ida-msgbox"
      @click.self="handleAction('close')"
    >
      <div class="ida-msgbox-content">
        <i
          class="ida-msgbox-close ida-icon-cross-light"
          @click.self="handleAction('close')"
        />
        <div class="ida-msgbox-header">
          <div class="ida-msgbox-header__icon">
            <svgicon name="big-link" original />
          </div>
        </div>

        <h2>{{ $t('app.live.export.title') }}</h2>
        <p>{{ $t('app.live.export.content') }}</p>

        <div class="links">
          <div class="links-header">
            <div class="links-header__items">
              <div
                ref="javascript"
                class="links-header__item links-header__item--javascript"
                :class="{isActive: activeTab === 'javascript'}"
                @click="toggleTab('javascript', $event)"
              >
                JavaScript
              </div>
              <div
                ref="iframe"
                class="links-header__item links-header__item--iframe"
                :class="{isActive: activeTab === 'iframe'}"
                @click="toggleTab('iframe', $event)"
              >
                iFrame
              </div>
              <div
                ref="ssr"
                class="links-header__item links-header__item--ssr"
                :class="{isActive: activeTab === 'ssr'}"
                @click="toggleTab('ssr', $event)"
              >
                Rendu serveur
              </div>
              <div
                ref="amp"
                class="links-header__item links-header__item--amp"
                :class="{isActive: activeTab === 'amp'}"
                @click="toggleTab('amp', $event)"
              >
                AMP
              </div>
              <div
                ref="ampiframe"
                class="links-header__item links-header__item--ampiframe"
                :class="{isActive: activeTab === 'ampiframe'}"
                @click="toggleTab('ampiframe', $event)"
              >
                AMP iFrame
              </div>
            </div>

            <div class="links-header__divider">
              <span
                ref="linksDivider"
                class="line"
              />
            </div>
          </div>

          <div class="ida-msgbox-action">
            <div class="ida-msgbox-action__code">
              <ida-input
                v-model="code"
                spellcheck="false"
                autocorrect="false"
                readonly="readonly"
              />
              <button
                type="button"
                class="ida-button ida-button--primary js-button-copy animated--slide-vertical"
                @click="copy(code)"
              >
                <span
                  class="ida-button--span"
                  @click="copy"
                >
                  <i class="back"><i class="ida-icon-check" /></i>
                  <i class="front">{{ $t('app.live.export.actions.copy') }}</i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getExportCodes } from '@/helpers/utils/others'

export default {
  name: 'Export',
  data () {
    return {
      code: '',
      activeTab: ''
    }
  },
  computed: {
    isExportModalOpen: {
      get () { return this.$store.getters['export/isModalOpen'] },
      set (boolean) { this.$store.commit('export/SET_IS_MODAL_OPEN', boolean) }
    }
  },
  mounted () {
    // Simulate click on first item to fill code input
    document.querySelector('.links-header__item').click()

    document.body.addEventListener('keyup', e => {
      // Handle closing popup on Esc key press
      if (e.keyCode === 27) { this.handleAction('close') }
    })
  },
  methods: {
    handleAction (action = 'close') {
      document.removeEventListener('keydown', this.keydownEvent)
      this.isExportModalOpen = false
    },
    toggleTab (tab) {
      this.activeTab = tab
      this.calcDividerWidth(tab)
      this.code = getExportCodes(this.$route.params.id)[this.activeTab]

      // Remove active state (handling tab fast-switching)
      document.querySelector('.ida-msgbox-action').classList.remove('animated-active')
    },
    async copy (text) {
      try {
        await this.$clipboard(text)

        // Copy to clipboard button animation
        const classes = document.querySelector('.ida-msgbox-action').classList
        const time = 1.7
        if (!classes.contains('animated-active')) {
          classes.add('animated-active')
          setTimeout(function () {
            classes.remove('animated-active')
          }, time * 1000)
        }
      } catch (e) {
        console.error(e)
      }
    },
    calcDividerWidth (tab) {
      const parentClass = '.links-header'
      if (this.$refs[tab]) {
        const line = this.$refs[tab].getBoundingClientRect()
        const parent = document.querySelector(parentClass).getBoundingClientRect()
        this.$refs.linksDivider.style.width = `${line.width}px`
        this.$refs.linksDivider.style.left = `calc(${line.left}px - ${parent.left}px)`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/export.scss';
</style>

<style lang="scss">
@import '@/assets/stylesheets/variables.scss';

/* Override styles (not scoped) */
.export {
  .ida-input .ida-input__inner {
    background: #fbfbfb;
    border: 1px solid #ededed;
    font-size: 12px;
    padding: 10px;
  }

  .animated-active {
    .ida-input > input {
      border-color: $--color-primary !important;
    }
  }
}
</style>
