import { Node } from 'tiptap'
import { nodeInputRule } from 'tiptap-commands'
import Facebook from './views/Facebook'

const FACEBOOK_REGEX = /http(?:s)?:\/\/(?:www\.)?facebook\.com\/p\/([a-zA-Z0-9_]+)/

export default class FacebookPlugin extends Node {
  get name () {
    return 'facebook'
  }

  get schema () {
    return {
      attrs: {
        src: {
          default: null
        }
      },
      group: 'block',
      parseDOM: [
        {
          tag: 'facebook',
          getAttrs: dom => ({
            src: dom.getAttribute('src') ? dom.getAttribute('src') : dom.getAttribute('data-src')
          })
        }
      ],
      toDOM: node => [
        'facebook',
        { class: 'fb-post', 'data-src': node.attrs.src },
        ['div', { class: 'fb__post-container' }, node.attrs.src]
      ]
    }
  }

  commands ({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state
      const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
      const node = type.create(attrs)
      const transaction = state.tr.insert(position, node)
      dispatch(transaction)
    }
  }

  inputRules ({ type }) {
    return [
      nodeInputRule(FACEBOOK_REGEX, type, match => {
        const [, src] = match
        return {
          src
        }
      })
    ]
  }

  get view () {
    return Facebook
  }
}
