<template>
  <ida-topbar
    :brand="$t('app.title')"
    :tabs="tabs"
    :c-t-a="$t('app.topbar.cta')"
    product="LIVEBLOG"
    :account-options="accountOptions"
    :user="userFullName"
    :locale="currentLanguage"
    :languages="languages"
    :current-language="currentLanguage"

    @accountOptionClick="handleAccountOptionClick"
    @brandClick="handleLinkClick('archives')"
    @CTAClick="handleCTAClick"
    @languageClick="handleLanguageClick"
    @tabClick="handleLinkClick"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import urls from '@/helpers/constants/urls'

export default {
  name: 'Topbar',
  computed: {
    ...mapGetters({
      userFullName: 'user/fullName'
    }),
    tabs () {
      return [
        {
          link: 'archives',
          text: this.$t('app.topbar.archives'),
          current: this.$route.name === 'archives'
        },
        {
          link: 'customerSettings',
          text: this.$t('app.topbar.settings'),
          current: this.$route.name === 'customerSettings'
        }
      ]
    },
    currentLanguage () {
      return this.$i18n.locale
    },
    languages () {
      return Object.keys(this.$i18n.messages)
    },
    accountOptions () {
      return {
        logout: this.$t('app.topbar.account.logout')
      }
    }
  },
  methods: {
    handleLinkClick (link) {
      this.$router.push({ name: link })
    },
    handleLanguageClick (locale) {
      this.$i18n.locale = locale.toLowerCase()
      Cookies.set('locale', locale, { expires: 20000, domain: urls.cookieDomain })
    },
    handleAccountOptionClick (key) {
      switch (key) {
        case 'help':
          this.$store.commit('event/ONBOARDING_VISIBILITY_UPDATED', true)
          break
        case 'logout':
          this.$store.dispatch('user/logout')
          break
        default:
          break
      }
    },
    handleCTAClick () {
      this.$router.push({ name: 'newLiveblog' })
    }
  }
}
</script>

<style lang="scss">
#idalgo-app {
  .ida-topbar {
    .ida-topbar-tools .ida-topbar-account-options .account-options-profile-initials {
      font-weight: 700;
      font-size: 12px;
    }

    .ida-topbar-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 768px;
      max-width: unset;
      height: 100%;
      padding-left: 8vw;
      padding-right: 8vw;
    }
  }
}
</style>
