class EmbedInstagram {
  constructor () {
    this.exists = !!document.querySelector('.instagram-media')
  }

  async check () {
    while (!window.instgrm || !window.instgrm.Embeds) {
      await new Promise((resolve, reject) => setTimeout(resolve, 50))
    }
  }

  async reload () {
    if (this.exists) {
      await this.check()
      window.instgrm.Embeds.process()
    }
  }
}

export default EmbedInstagram
