<template>
  <section class="quote-container" :data-content="quoteContent" :data-name="quoteName">
    <input v-model="quoteName" class="quote__name-container" contenteditable="true">
    <TextareaAutosize v-model="quoteContent" :max-height="200" class="quote__content-container" contenteditable="true" />
  </section>
</template>

<script>
export default {
  name: 'Quote',
  props: ['node'],
  computed: {
    quoteContent: {
      get () { return this.$store.getters['posts/quoteContent'] },
      set (quote) { this.$store.commit('posts/SET_QUOTE_CONTENT', quote) }
    },
    quoteName: {
      get () { return this.$store.getters['posts/quoteName'] },
      set (name) { this.$store.commit('posts/SET_QUOTE_NAME', name) }
    },
    quoteAnswer: {
      get () { return this.$store.getters['posts/quoteAnswer'] },
      set (answer) { this.$store.commit('posts/SET_QUOTE_ANSWER', answer && answer.replace(/"/g, '')) }
    }
  },
  mounted () {
    this.quoteContent = this.node && this.node.attrs.content
    this.quoteName = this.node && this.node.attrs.name
    this.quoteAnswer = this.node && this.node.attrs.answer

    setTimeout(() => {
      let inputQuestion = document.querySelector('.quote__content-container')
      this.event(inputQuestion, 'change', this.resize)
      this.event(inputQuestion, 'drop', this.resize)
      this.event(inputQuestion, 'keydown', this.resize)
      this.event(inputQuestion, 'cut', this.resize)
      this.event(inputQuestion, 'paste', this.resize)
    }, 1)
  },
  methods: {
    resize () {
      let text = document.querySelector('.quote__content-container')
      text.style.height = 'auto'
      text.style.height = text.scrollHeight + 'px'
    },
    event (obj, e, handler) {
      if (window.attachEvent) {
        obj.attachEvent('on' + e, handler)
      } else {
        obj.addEventListener(e, handler, false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-container {
  padding: 20px;
  background: rgba(0, 0, 0, 0.05);

  input,
  textarea {
    background: transparent;
    margin-bottom: 0;
  }

  .quote__content-container {
    width: 100%;
    border: none;
    overflow-y: none;
    font-size: 14px;
    resize: vertical;
    min-height: 20px;
  }

  .quote__name-container {
    width: 100%;
    border: none;
    resize: none;
    font-size: 14px;
    height: 24px;
    margin: 0;
    padding: 0;
    font-weight: 700;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &::before {
      font-family: ida-icons !important;
      content: '\ea19';
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }

  .quote__content-container {
    color: #424242;
    font-size: 14px;
    opacity: 1;
  }

  .quote__content-container,
  .quote__name-container {
    &:focus {
      outline: 0;
    }
  }
}
</style>
