import url from 'url'

const getHostname = () => {
  return window.location.hostname !== 'localhost' ? 'service.idalgo.io' : 'localhost'
}

const getUrlWithProtocol = host => {
  const protocol = host.indexOf('localhost') !== -1 ? 'http' : 'https'
  return url.format({
    protocol,
    host
  })
}

const isValidAnchor = url => {
  return !!url.match(/(#\d?[^\s]+)/g)
}

const isValidUrl = url => {
  return !!url.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)
}

export { getHostname, getUrlWithProtocol, isValidAnchor, isValidUrl }
