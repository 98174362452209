<template>
  <div class="liveblog-editor__content">
    <Export v-if="isExportModalOpen" />
    <Survey v-if="isSurveyModalOpen" />

    <div class="dashboard-container">
      <ActionContainer v-if="actions && actions.length" />

      <div class="editor">
        <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
          <div class="menubar">
            <div class="menubar__left">
              <button
                v-tippy="{ html: '#tooltip' }"
                class="menubar__button menubar__button--bold"
                :class="{ 'is-active': isActive.bold() }"
                :data-tip="$t('app.editor.menubar.bold')"
                data-macos="Cmd + B"
                data-windows="Ctrl + B"
                @click="commands.bold"
                @mouseover="updateTooltip"
                @keyup.tab="updateTooltip"
              >
                <i class="ida-icon-bold" />
              </button>

              <button
                v-tippy="{ html: '#tooltip' }"
                class="menubar__button menubar__button--italic"
                :class="{ 'is-active': isActive.italic() }"
                :data-tip="$t('app.editor.menubar.italic')"
                data-macos="Cmd + I"
                data-windows="Ctrl + I"
                @click="commands.italic"
                @mouseover="updateTooltip"
                @keyup.tab="updateTooltip"
              >
                <i class="ida-icon-italic" />
              </button>

              <button
                v-tippy="{ html: '#tooltip' }"
                class="menubar__button menubar__button--underline"
                :class="{ 'is-active': isActive.underline() }"
                :data-tip="$t('app.editor.menubar.underline')"
                data-macos="Cmd + U"
                data-windows="Ctrl + U"
                @click="commands.underline"
                @mouseover="updateTooltip"
                @keyup.tab="updateTooltip"
              >
                <i class="ida-icon-underline" />
              </button>

              <button
                v-tippy="{ html: '#tooltip' }"
                class="menubar__button menubar__button--bulletList"
                :class="{ 'is-active': isActive.bullet_list() }"
                :data-tip="$t('app.editor.menubar.bulletList')"
                @click="commands.bullet_list"
                @mouseover="updateTooltip"
                @keyup.tab="updateTooltip"
              >
                <i class="ida-icon-list" />
              </button>

              <button
                v-tippy="{ html: '#tooltip' }"
                class="menubar__button menubar__button--link"
                :class="{ 'is-active': isActive.link() }"
                :data-tip="$t('app.editor.menubar.link')"
                @click="showPrompt(embedTypes.LINK)"
                @mouseover="updateTooltip"
                @keyup.tab="updateTooltip"
              >
                <i class="ida-icon-link" />
              </button>

              <button
                v-tippy="{ html: '#tooltip' }"
                class="menubar__button menubar__button--image"
                :data-tip="$t('app.editor.menubar.image')"
                @click="openUploadImage"
                @mouseover="updateTooltip"
                @keyup.tab="updateTooltip"
              >
                <i class="ida-icon-img" />
              </button>

              <button
                v-tippy="{ html: '#tooltip' }"
                class="menubar__button menubar__button--twitter"
                :data-tip="$t('app.editor.menubar.twitter')"
                @click="showPrompt(embedTypes.TWITTER)"
                @mouseover="updateTooltip"
                @keyup.tab="updateTooltip"
              >
                <svgicon name="x" class="ida-icon-twitter" original />
              </button>

              <button
                v-tippy="{ html: '#tooltip' }"
                class="menubar__button menubar__button--facebook"
                :data-tip="$t('app.editor.menubar.facebook')"
                @click="showPrompt(embedTypes.FACEBOOK)"
                @mouseover="updateTooltip"
                @keyup.tab="updateTooltip"
              >
                <i class="ida-icon-facebook" />
              </button>

              <button
                v-tippy="{ html: '#tooltip' }"
                class="menubar__button menubar__button--code"
                :data-tip="$t('app.editor.menubar.code')"
                @click="showPrompt(embedTypes.CODE)"
                @mouseover="updateTooltip"
                @keyup.tab="updateTooltip"
              >
                <i class="ida-icon-code" />
              </button>

              <button
                v-tippy="{ html: '#tooltip' }"
                class="menubar__button menubar__button--survey"
                :data-tip="$t('app.editor.menubar.survey')"
                @click="openSurvey"
                @mouseover="updateTooltip"
                @keyup.tab="updateTooltip"
              >
                <i class="ida-icon-stats" />
              </button>
            </div>

            <div v-if="isUploadImageModalOpen">
              <UploadImage :callback="commands.image" />
            </div>
            <div v-if="isEmbedModalOpen" class="embed__modal">
              <EmbedModal :commands="commands" />
            </div>

            <div class="menubar__right">
              <div v-if="isPostEditing">
                <ida-button
                  v-tippy="{ html: '#tooltip' }"
                  class="ida-button--cancel"
                  type="default"
                  plain
                  height="35"
                  width="45"
                  size="medium"
                  :data-tip="$t('app.editor.menubar.cancel')"
                  @mouseover="updateTooltip"
                  @keyup.tab="updateTooltip"
                  @click="clear"
                >
                  <i class="ida-icon-cross-light" />
                </ida-button>
                <ida-button
                  v-tippy="{ html: '#tooltip' }"
                  class="ida-button--update"
                  type="secondary"
                  height="35"
                  width="45"
                  size="medium"
                  :data-tip="$t('app.editor.menubar.update')"
                  :disabled="isSaving"
                  @mouseover="updateTooltip"
                  @keyup.tab="updateTooltip"
                  @click="pushUpdate"
                >
                  <Loader v-if="isSaving" />
                  <i v-else class="ida-icon-edit" />
                </ida-button>
              </div>
              <div v-else-if="isCommentAnswering">
                <ida-button
                  v-tippy="{ html: '#tooltip' }"
                  class="ida-button--cancel"
                  type="default"
                  plain
                  height="35"
                  width="45"
                  size="medium"
                  :data-tip="$t('app.editor.menubar.cancel')"
                  @mouseover="updateTooltip"
                  @keyup.tab="updateTooltip"
                  @click="clear"
                >
                  <i class="ida-icon-cross-light" />
                </ida-button>
                <ida-button
                  v-tippy="{ html: '#tooltip' }"
                  class="ida-button--publish"
                  type="primary"
                  height="35"
                  width="45"
                  size="medium"
                  :data-tip="$t('app.editor.menubar.publish')"
                  @mouseover="updateTooltip"
                  @keyup.tab="updateTooltip"
                  @click="push"
                >
                  <i class="ida-icon-plane" />
                </ida-button>
              </div>
              <div v-else>
                <ida-button
                  v-tippy="{ html: '#tooltip' }"
                  class="ida-button--publish"
                  type="primary"
                  height="35"
                  width="45"
                  size="medium"
                  :data-tip="$t('app.editor.menubar.publish')"
                  :disabled="isSaving"
                  @mouseover="updateTooltip"
                  @keyup.tab="updateTooltip"
                  @click="push"
                >
                  <Loader v-if="isSaving" />
                  <i v-else class="ida-icon-plane" />
                </ida-button>
              </div>
            </div>
          </div>
        </editor-menu-bar>

        <div class="editor-input-group">
          <div v-if="action && action.id" class="editor-input-group-inside">
            <div class="inputs">
              <ida-guided-search
                ref="guidedSearch"
                :selected-choice="dataPlayer"
                :data-sources="dataSources"
                @inputChange="handlePlayerChange"
                @choicesChange="handleChoicesChange"
              />
            </div>
          </div>
        </div>

        <div class="content">
          <div class="input-group">
            <input
              v-model="title"
              :placeholder="$t('app.editor.title.placeholder')"
              class="title-input"
            >
          </div>
          <editor-content class="editor__content" :editor="editor" />
        </div>
      </div>

      <EditorPreview />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Bold, Italic, Underline, BulletList, OrderedList, ListItem,
  Placeholder, History
} from 'tiptap-extensions'
import { mapGetters } from 'vuex'
import ActionContainer from '@/components/Action/ActionContainer'
import { embedTypes } from '@/helpers/constants/embeds'
/* preview & custom plugins */
import EditorPreview from './EditorPreview'
import CustomLink from './CustomPlugins/CustomLink'
import FacebookPlugin from './CustomPlugins/FacebookPlugin'
import GlobalCodePlugin from './CustomPlugins/GlobalCodePlugin'
import ImagePlugin from './CustomPlugins/ImagePlugin'
import InstagramPlugin from './CustomPlugins/InstagramPlugin'
import QuotePlugin from './CustomPlugins/QuotePlugin'
import TwitterPlugin from './CustomPlugins/TwitterPlugin'
/* other components */
import EmbedModal from './EmbedModal'
import Export from './Export'
import Survey from './Survey'
import UploadImage from './UploadImage'
import { WebsocketAPI, WebsocketResponse } from '../../api/WebsocketAPI'

export default {
  name: 'Editor',
  components: {
    ActionContainer,
    EditorContent,
    EditorMenuBar,
    EditorPreview,
    EmbedModal,
    Export,
    Survey,
    UploadImage
  },
  data () {
    return {
      editor: new Editor({
        extensions: [
          new Bold(),
          new Italic(),
          new Underline(),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new History(),
          new Placeholder({ emptyNodeText: this.$t('app.editor.content.placeholder') }),
          // custom tiptap plugins below
          new TwitterPlugin(),
          new InstagramPlugin(),
          new FacebookPlugin(),
          new GlobalCodePlugin(),
          new ImagePlugin(),
          new QuotePlugin(),
          new CustomLink()
        ],
        content: this.content,
        onUpdate: ({ getHTML }) => {
          this.content = getHTML()
        },
        parseOptions: {
          preserveWhitespace: true
        }
      }),
      embedTypes
    }
  },
  computed: {
    ...mapGetters({
      isSaving: 'editor/isSaving',
      isPostEditing: 'editor/isPostEditing',
      isCommentAnswering: 'editor/isCommentAnswering',
      actions: 'dashboardActions/actions',
      currentComment: 'comments/currentComment',
      currentPost: 'posts/currentPost',
      isContentNull: 'posts/isContentNull',
      isTitleNull: 'posts/isTitleNull',
      isEmbedModalOpen: 'medias/isModalOpen',
      isErrorActive: 'posts/isErrorActive',
      isExportModalOpen: 'export/isModalOpen',
      isNewLive: 'lives/isNewLive',
      isUploadImageModalOpen: 'uploadImage/isModalOpen',
      postIndex: 'posts/postIndex',
      isSurveyModalOpen: 'survey/isModalOpen',
      formattedTopic: 'lives/formattedTopic'
    }),
    title: {
      get () { return this.$store.getters['posts/currentPostTitle'] },
      set (title) { this.$store.commit('posts/SET_CURRENT_TITLE', title) }
    },
    content: {
      get () { return this.$store.getters['posts/currentPostContent'] },
      set (content) { this.$store.commit('posts/SET_CURRENT_CONTENT', content) }
    },
    action: {
      get () { return this.$store.getters['dashboardActions/action'] },
      set (action) { this.$store.dispatch('dashboardActions/setAction', action) }
    },
    dataPlayer: {
      get () { return this.$store.getters['dashboardActions/dataPlayer'] },
      set (value) { this.$store.commit('dashboardActions/SET_PLAYER_DATA', value) }
    },
    dataSources () {
      return [
        {
          category: this.$t('app.editor.answer.category.player'),
          request: window.wsAPI.searchPersonByName,
          limit: 3
        },
        {
          category: this.$t('app.editor.answer.category.team'),
          request: this.searchTeamByNameRequest,
          limit: 3
        }
      ]
    },
    searchTeamByNameRequest () {
      switch (this.formattedTopic?.wsSport) {
        case (WebsocketAPI.SPORT_FOOTBALL):
          return window.wsAPI.searchFootballTeamByName
        case (WebsocketAPI.SPORT_RUGBY):
          return window.wsAPI.searchRugbyTeamByName
        case (WebsocketAPI.SPORT_MULTISPORTS):
          return window.wsAPI.searchMultisportsTeamByName
        default:
          return () => new WebsocketResponse([])
      }
    }
  },
  mounted () {
    this.$root.$on('clear', this.clear)
    this.$root.$on('editPost', this.editPost)
    this.$root.$on('answerComment', this.answerComment)
  },
  beforeDestroy () {
    this.$store.dispatch('posts/resetPost')
    if (this.editor) { this.editor.destroy() }
  },
  methods: {
    async editPost (post) {
      this.clear()
      this.$store.dispatch('posts/openEditMode', post)
      await this.editor.setContent(this.content)

      if (this.currentPost.action) {
        await this.$store.dispatch('posts/fetchAction')
        setTimeout(() => this.loadPlayer(), 150)
      }
    },
    async answerComment (comment) {
      this.clear()
      this.$store.dispatch('comments/switchToAnswerComment', comment)
      await this.editor.setContent(`<section data-name="${this.currentComment.author}" data-content="${this.currentComment.content}" data-answer=""></section>`)
    },
    handlePlayerChange (data) { this.dataPlayer = data },
    loadPlayer () {
      // Display name and image URL (ida-guided-search)
      if (this.$refs.guidedSearch && this.$refs.guidedSearch.$children[0].$children[0]) {
        this.$refs.guidedSearch.$children[0].$children[0].value = this.dataPlayer.value
        this.$refs.guidedSearch.$children[0].$children[0].$el.children[1].children[0].style.backgroundImage = `url(${this.dataPlayer.imageUrl})`
      }
    },
    async pushUpdate () {
      if (this.isContentNull && this.isTitleNull) {
        this.$noty.error(this.$t('app.editor.error.content'))
      } else {
        await this.$store.dispatch('posts/updatePost')
        this.$store.commit('editor/SET_IS_SAVING', true)
      }
    },
    async push () {
      if (this.isContentNull && this.isTitleNull) {
        this.$noty.error(this.$t('app.editor.error.content'))
      } else {
        await this.$store.dispatch('posts/createPost', this.$route.params.id)
        document.querySelector('.posts-container').scrollTop = 0
        document.querySelector('.preview-header__item--live').click()
        this.$store.commit('editor/SET_IS_SAVING', true)
      }
    },
    showPrompt (inputType) {
      this.$store.commit('medias/SET_TYPE', inputType)
      this.$store.commit('medias/TOGGLE_OPEN_MODAL', true)
    },
    clear () {
      this.$store.dispatch('posts/resetPost')
      this.editor.clearContent(true)
      this.dataPlayer = {}
    },
    handleChoicesChange () {
      if (
        this.$refs.guidedSearch &&
        this.$refs.guidedSearch.$children[0].$children[0]
      ) {
        this.$refs.guidedSearch.$children[0].$children[0].$el.children[1].children[0].style.backgroundImage = `null`
      }
    },
    openSurvey () {
      setTimeout(() => { this.$store.dispatch('survey/openCreationModal') }, 10) // Fix: setTimeout here to allow closing from dashboard page
    },
    openUploadImage () {
      setTimeout(() => { this.$store.dispatch('uploadImage/openModal') }, 10) // Fix: setTimeout here to allow closing from dashboard page
    },
    updateTooltip (e) {
      this.$root.$emit('updateTooltip', e)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/stylesheets/components/editor/editor.scss';
@import '@/assets/stylesheets/components/editor/editor-prosemirror.scss';

.ida-button--publish[disabled],
.ida-button--update[disabled] {
  border-color: #eeeeee !important;
  background: #eeeeee !important;
  color: #bbbbbb;
}
</style>
