/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 22,
    height: 42,
    viewBox: '0 0 22 42',
    data: '<path pid="0" d="M.293 40.293a1 1 0 001.414 1.414l20-20a1 1 0 000-1.414l-20-20A1 1 0 00.293 1.707L19.595 21.01.293 40.293z" _fill="#000" fill-rule="evenodd"/>'
  }
})
