<template>
  <div v-if="action" class="action-container">
    <div class="action-container-card" :data-id="action.id">
      <div class="action-data-container" @click="handleActionClick(action)">
        <div class="action-icon">
          <div v-if="action.picto" class="picto" v-html="action.picto" />
          <i v-else class="ida-icon-liveblog" />
        </div>
        <div class="action-title">
          {{ actionName }}
        </div>
      </div>

      <div class="action-icons-container" @click="toggleFavourite(action.id)">
        <i v-if="isActionSelected(action.id)" class="ida-icon-star-rounded-filled" />
        <i v-else class="ida-icon-star-rounded" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ActionCard',
  props: {
    action: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      currentLive: 'lives/currentLive'
    }),
    actionName () {
      return this.action[this.$i18n.locale]
    },
    active: {
      get () { return this.$store.getters['dashboardActions/action'] },
      set (action) { this.$store.dispatch('dashboardActions/setAction', action) }
    },
    isActionSelected () {
      return actionId => this.currentLive.favouriteActions && this.currentLive.favouriteActions.includes(actionId)
    }
  },
  methods: {
    handleActionClick (action) {
      if (this.active && this.active.id === action.id) {
        this.active = {}
        this.$store.commit('posts/SET_CURRENT_HIGHLIGHTED', false)
      } else {
        this.active = action
      }
    },
    toggleFavourite (actionId) {
      const path = this.isActionSelected(actionId) ? 'lives/removeFromFavouriteActions' : 'lives/addToFavouriteActions'
      this.$store.dispatch(path, actionId)
    }
  }
}
</script>
