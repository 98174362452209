<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li
        v-for="(item, i) in crumbs"
        :key="i"
        :class="item.classes"
      >
        <router-link :to="item.path">
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currentLive: 'lives/currentLive'
    }),
    formatTitle () {
      return title => {
        return title.length > 50 ? title.substring(0, 50).concat('...') : title
      }
    },
    crumbs () {
      const crumbs = []

      const words = this.$route.path.replace('app/', '').substr(1).split('/')
      let path = '/app'

      for (let i = 0; i < words.length; i++) {
        path = `${path}/${words[i]}`
        const page = path.split('/').pop()
        const uuidv4 = /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i
        const isLive = page.match(uuidv4)
        let name

        if (isLive) {
          name = `[Live] ${this.formatTitle(this.currentLive.title || '...')}`
        } else {
          name = this.$i18n.t(`app.breadcrumb.${page}`)
        }

        crumbs.push({
          path: path.replace(/\/editor$/, '/'),
          name,
          classes: `${i === words.length - 1 ? 'is-active' : ''} ${isLive ? 'live-title' : ''}`
        })
      }

      return crumbs
    }
  }
}
</script>

<style lang="scss" scoped>
nav.breadcrumb {
  font-size: 16px;
  margin-bottom: 12px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > :not(:last-child) {
      margin-right: 5px;

      &::after {
        content: '/';
        color: rgba(66, 66, 66, 0.5);
        margin-left: 2px;
      }
    }
  }

  li {
    display: inline-block;

    a {
      color: rgba(66, 66, 66, 0.5);
      transition: 0.3s;

      &:hover {
        color: #424242;
      }
    }

    &.is-active {
      a {
        color: #424242;
      }
    }
  }
}
</style>
