/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'loader': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<defs><linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="svgicon_loader_a"><stop stop-color="#949494" stop-opacity="0" offset="0%"/><stop stop-color="#949494" stop-opacity=".631" offset="63.146%"/><stop stop-color="#949494" offset="100%"/></linearGradient></defs><g transform="translate(1 1)" _fill="none" fill-rule="evenodd"><path pid="0" d="M36 18c0-9.94-8.06-18-18-18" _stroke="url(#svgicon_loader_a)" stroke-width="2"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/></path><circle pid="1" _fill="#949494" cx="36" cy="18" r="1"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/></circle></g>'
  }
})
