import Vue from 'vue'
import VueNoty from 'vuejs-noty'
import '@/assets/stylesheets/plugins/vuejs-noty.custom.scss'

Vue.use(VueNoty, {
  timeout: 3000,
  progressBar: false,
  layout: 'topRight',
  theme: 'idalgo',
  closeWith: ['button'],
  resetTimerOnHover: false,
  killer: true,
  visibilityControl: false
})
