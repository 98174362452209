const EditorModes = {
  POST_EDITING: 'post_editing',
  COMMENT_ANSWERING: 'comment_answering'
}

const PreviewTabs = {
  LIVE: 'live',
  PINNED: 'pinned',
  COMMENTS: 'comments'
}

export { EditorModes, PreviewTabs }
