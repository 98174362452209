import { Node } from 'tiptap'
import { nodeInputRule } from 'tiptap-commands'
import Twitter from './views/Twitter'

const TWITTER_REGEX = /http(?:s)?:\/\/(?:www\.)?(?:twitter|x)\.com\/p\/([a-zA-Z0-9_]+)/

const getSrc = src => {
  if (src.includes('blockquote')) {
    const regex = new RegExp(/[";]+(https:\/\/(?:twitter|x)\.com\/([\s\S][^/]*?)\/status\/([\s\S]*?))[?"&]/)
    const link = regex.exec(src)[1]
    return link
  } else {
    return src
  }
}

export default class TwitterPlugin extends Node {
  get name () {
    return 'twitter'
  }

  get schema () {
    return {
      attrs: {
        src: {
          default: null
        }
      },
      group: 'block',
      parseDOM: [
        {
          tag: 'twitter',
          class: 'twitter-container',
          getAttrs: dom => ({
            src: dom.getAttribute('src') ? dom.getAttribute('src') : dom.getAttribute('data-src')
          })
        }
      ],
      toDOM: node => [
        'twitter',
        { class: 'twitter-tweet', 'data-src': getSrc(node.attrs.src) },
        ['div', { class: 'tweet-container', 'data-src': getSrc(node.attrs.src) }]
      ]
    }
  }

  commands ({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state
      const position = selection.$cursor
        ? selection.$cursor.pos
        : selection.$to.pos

      if (attrs.src && !attrs.src.includes('blockquote')) { attrs.src = attrs.src.split('?').shift() }

      const node = type.create(attrs)
      const transaction = state.tr.insert(position, node)
      dispatch(transaction)
    }
  }

  inputRules ({ type }) {
    return [
      nodeInputRule(TWITTER_REGEX, type, match => {
        const [, src] = match
        return {
          src
        }
      })
    ]
  }

  get view () {
    return Twitter
  }
}
