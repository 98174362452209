<template>
  <div class="layout layout--viewer">
    <router-view />
  </div>
</template>

<script>
import websockets from '@/constants/websockets'

export default {
  mounted () {
    this.$nextTick(() => {
      this.$socket().on(websockets.NOTY_ERROR, ({ translation }) => this.$noty.error(this.$t(translation)))
    })
  }
}
</script>
