import { Node } from 'tiptap'
import { nodeInputRule } from 'tiptap-commands'
import Quote from './views/Quote'

const GLOBAL_REGEX = /iframe|blockquote/

export default class QuotePlugin extends Node {
  get name () {
    return 'quote'
  }

  get schema () {
    return {
      group: 'block',
      attrs: {
        content: {
          default: null
        },
        name: {
          default: null
        }
      },
      draggable: true,
      selectable: false,
      parseDOM: [
        {
          tag: 'section',
          class: 'quote-container',
          getAttrs: dom => ({
            content: dom.getAttribute('content') ? dom.getAttribute('content') : dom.getAttribute('data-content'),
            name: dom.getAttribute('name') ? dom.getAttribute('name') : dom.getAttribute('data-name')
          })
        }
      ],
      toDOM: node => [
        'section',
        {
          'data-content': node.attrs.content,
          'data-name': node.attrs.name
        },
        [
          'div',
          { class: 'quote-container' },
          [
            'div',
            { class: 'quote__content-container' },
            node.attrs.content
          ],
          [
            'div',
            { class: 'quote__name-container' },
            node.attrs.name
          ]
        ]
      ]
    }
  }

  commands ({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state
      const position = selection.$cursor
        ? selection.$cursor.pos
        : selection.$to.pos

      const node = type.create(attrs)
      const transaction = state.tr.insert(position, node)
      dispatch(transaction)
    }
  }

  inputRules ({ type }) {
    return [
      nodeInputRule(GLOBAL_REGEX, type, match => {
        const [, src] = match
        return {
          src
        }
      })
    ]
  }

  get view () {
    return Quote
  }
}
